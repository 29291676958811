import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import { Slider } from 'rsuite';
// import JSPath from 'jspath';
// import { globals } from '../globals';
// import Helpers from '../helpers';

export default class DimmerSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimmerValue: 89
    };
  }

  componentWillMount() {

  }

  render() {
    console.log(
      'rendering [DimmerSlider]',
      // { props: this.props },
      { state: this.state }
    );

    const { dimmerValue } = this.state;

    return (
      <Slider
        style={{ width: 70 }}
        min={85}
        max={93}
        value={dimmerValue}
        tooltip={false}
        onChange={(value) => {
          const newDimmerValue = value < 60 ? 60 : value;
          const darkModeLayer = document.querySelector('.darkmode-layer--button');
          darkModeLayer.style.setProperty('opacity', `${parseFloat(newDimmerValue / 100)}`);
          this.setState({ dimmerValue: newDimmerValue });
        }}
        className="darkmode-dimmer-slider"
      />
    );
  }
}

// DimmerSlider.propTypes = {
//   searchText: PropTypes.string,
//   wordClickHandler: PropTypes.func
// };
