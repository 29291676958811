import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ViewFormat from './ViewFormat';
import Helpers from '../../helpers';
import { globals } from '../../globals';
import SvgLib from '../Assets/img/SvgLib';

const Images = (props) => {
  console.log('rendering Images', props);

  const { data } = props;
  const { activeModeTab } = props;
  const { activeSourceTab } = props;
  const { imageClickHandler } = props;
  const { mouseWheelHandler } = props;
  const { nextImageClickHandler } = props;
  const { prevImageClickHandler } = props;

  const { results } = data;

  if (!Helpers.isObjectSet(results)) {
    return (``);
  }

  //* RETURN EMPTY WHEN NOTHING TO SHOW
  const resultKeys = Object.keys(results);
  const gotData = resultKeys
    .map(k => results[k].length > 0)
    .filter(x => x === true)
    .length > 0;
  if (!gotData) {
    return (``);
  }

  //* PREP & RETURN AS HTML COMP.

  // ? Calculate Dynamic Image Height
  let foundIx = 0;
  const windowHeight = window.innerHeight;
  const { imageVersions } = globals.viewFormats;
  for (const [versionIx, versionItem] of imageVersions.entries()) {
    if (versionItem.rangeStart <= windowHeight
      && versionItem.rangeEnd >= windowHeight) {
      foundIx = versionIx;
      break;
    }
  }
  const { imageHeight } = imageVersions[foundIx]; /* 240, 340, 640 */
  const imageGalleryWrapperHeight = imageHeight + 20;

  // ? Format Image Items
  const sourceData = results[activeSourceTab]; // (object array)
  const items = [];
  if (Helpers.isArrayFull(sourceData)) {
    let itemKey = '';

    for (const [entryIx, entryItem] of sourceData.entries()) {
      const htmlComp = (
        <ViewFormat
          image={entryItem}
          imageHeight={imageHeight}
          imageClickHandler={imageClickHandler}
          isLastImage={(entryIx === sourceData.length - 1)}
        />
      );

      // add to panel
      itemKey = `${activeSourceTab}-${entryIx}`;
      items.push(
        <Fragment key={itemKey}>
          {htmlComp}
        </Fragment>
      );
    }
  }

  return (
    <Fragment>
      <div
        onWheel={mouseWheelHandler}
        className="scrollable-div scroll-horizontal image-gallery-wrapper"
        style={{ height: imageGalleryWrapperHeight }}
      >
        <div className="image-frame">
          {items}
        </div>
      </div>
      <div className="image-gallery-nav">
        <div
          role="presentation"
          className="image-gallery-navbtn-wrapper"
          onClick={prevImageClickHandler}
        >
          <SvgLib.prevImageBtnJsx />
        </div>
        <div className="horiz-spacer-div-2" />
        <div
          role="presentation"
          className="image-gallery-navbtn-wrapper"
          onClick={nextImageClickHandler}
        >
          <SvgLib.nextImageBtnJsx />
        </div>
      </div>
    </Fragment>
  );
};

Images.propTypes = {
  data: PropTypes.object,
  activeModeTab: PropTypes.string,
  activeSourceTab: PropTypes.string,
  imageClickHandler: PropTypes.func,
  mouseWheelHandler: PropTypes.func,
  prevImageClickHandler: PropTypes.func,
  nextImageClickHandler: PropTypes.func
};

export default Images;
