/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-unknown-property */
import React, { Component, Fragment } from 'react';
import clone from 'lodash.clone';
import {
  FlexboxGrid,
  Icon,
  Panel,
  Drawer
} from 'rsuite';
import Popover from 'react-text-selection-popover';
import { HotKeys } from 'react-hotkeys';
import { SearchBox } from './SearchBox';
import { FilterBox } from './FilterBox';
import { AboutModal } from './AboutModal';
import { SearchHistory } from './SearchHistory';
import ModeTabs from './ModeTabs';
import SourceTabs from './SourceTabs';
import LoadingText from './LoadingText';
import WhitespaceHolder from './WhitespaceHolder';
import NoResults from './NoResults';
import NoResultsTitlePanel from './NoResultsTitlePanel';
import ResultsTitlePanel from './ResultsTitlePanel';
import Definitions from './Definitions/Definitions';
import WebSearch from './WebSearch/WebSearch';
import Explore from './Explore/Explore';
import Examples from './Examples/Examples';
import Images from './Images/Images';
import Translator from './Translator/Translator';
import Pronounce from './Pronounce/Pronounce';
import NormalizeDefinitions from './Definitions/Normalize';
import NormalizeWebSearch from './WebSearch/Normalize';
import NormalizeExplore from './Explore/Normalize';
import NormalizeExamples from './Examples/Normalize';
import NormalizeImages from './Images/Normalize';
import NormalizeTranslator from './Translator/Normalize';
import Fetch from '../Fetch/Fetch';
import Helpers, { Storage } from '../helpers';
import { globals } from '../globals';

export class Body extends Component {
  constructor(props) {
    super(props);
    this.searchInputRefToBody = React.createRef();
    this.refSearchBox = React.createRef();
    this.filterInputRefToBody = React.createRef();
    this.refFilterBox = React.createRef();
    // this.aboutModalRefToBody = React.createRef();
    this.refAboutModal = React.createRef();
    this.refPopover = React.createRef();
    this.refPopover2 = React.createRef();
    this.state = {
      activeModeTab: 'definitions',
      activeSourceTab: 'mw',
      definitionsData: {},
      webData: {},
      exploreData: {},
      examplesData: {},
      imagesData: {},
      translatorData: {},
      filterText: '',
      sourcesList: [],
      loading: false,
      showDrawer: false // ,
      // showAboutModal: false
    };

    // ? use method binding at the constructor level to avoid binding at every render call
    this.handleModeSelect = this.handleModeSelect.bind(this);
    this.handleSourceSelect = this.handleSourceSelect.bind(this);
    this.handleSearchClick = this.handleSearchClick.bind(this);
    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.onWordClick = this.onWordClick.bind(this);
    this.onImageClick = this.onImageClick.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.prevImageClick = this.prevImageClick.bind(this);
    this.nextImageClick = this.nextImageClick.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
  }

  componentWillMount() {
    // called before any HTML element is rendered
  }

  getSearchText() {
    const searchText = this.searchInputRefToBody.current
      ? this.searchInputRefToBody.current.props.value.trim()
      : '';
    return searchText;
  }

  handleSearchClick = () => (e) => {
    e.preventDefault();
    this.performSearch();
  };

  handleFilterClick = () => (e) => {
    e.preventDefault();
    this.filterResults();
  };

  filterResults = () => {
    const filterText = this.filterInputRefToBody.current.props.value;
    // ! each setState calls render()
    this.setState({ filterText });
  }

  onWordClick = () => (e) => {
    if (e.ctrlKey || e.metaKey) {
      Helpers.removeAllSelection();
      this.searchSelectedWord(e.currentTarget.textContent);
    }
  };

  onMouseWheel = () => (e) => {
    // console.log('TCL: onMouseWheel -> e', e.nativeEvent);
    const delta = Math.max(-1, Math.min(1, (e.nativeEvent.wheelDelta || -e.nativeEvent.detail)));
    const direction = delta > 0 ? 'up' : 'down';
    this.scrollToImage(direction);
  }

  onImageClick = () => (e) => {
    const imageNode = e.currentTarget;
    console.log(imageNode.dataset.bigurl);
  };

  prevImageClick = () => (e) => {
    this.scrollToImage('up');
  };

  nextImageClick = () => (e) => {
    this.scrollToImage('down');
  };

  openDrawer = () => {
    this.setState({
      showDrawer: true
    });
  };

  closeDrawer = () => {
    this.setState({
      showDrawer: false
    });
  };

  // showAboutModal = () => {
  //   this.setState({
  //     showAboutModal: true
  //   });
  // };

  // hideAboutModal = () => {
  //   this.setState({
  //     showAboutModal: false
  //   });
  // };

  showAboutModal = () => {
    this.refAboutModal.current.open();
  }

  deleteInput = () => {
    this.refSearchBox.current.deleteInput();
    this.refFilterBox.current.deleteInput();
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToStart(targetSelector) {
    const el = document.querySelector(targetSelector);
    if (Helpers.isObjUseful(el)) {
      el.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  scroll(scrollType) {
    Helpers.removeAllSelection();
    const scrollArea = document.querySelector('.right-side .scrollable-div');
    if (this.state.activeModeTab === 'images') {
      const direction = scrollType.indexOf('up') > -1 ? 'up' : 'down';
      this.scrollToImage(direction);
    } else {
      this.scrollVertical(scrollType, scrollArea);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToImage(direction) {
    console.log(direction);
    const scrollArea = document.querySelector('.image-gallery-wrapper');
    if (Helpers.isObjUseful(scrollArea)) {
      const scrollAreaBounds = scrollArea.getBoundingClientRect();
      const images = document.querySelectorAll('.image-gallery-wrapper > .image-frame div.image-item');
      const { imageRightPadding, freeScrollOffset } = globals.images.config;

      const getImageBounds = (image) => {
        const imgBounds = image.getBoundingClientRect();
        const imageBounds = {
          delta: scrollAreaBounds.left - imgBounds.left,
          width: imgBounds.width + imageRightPadding
        };
        return imageBounds;
      };

      let scrollThis = false;
      for (const image of images) { // ? iterate image nodes in gallery
        const imageBounds = getImageBounds(image);
        const imageWidth = imageBounds.width;
        const imageDelta = imageBounds.delta;
        scrollThis = (
          (direction === 'down' && imageDelta === 0) // ? found currently visible image
          || (direction === 'up' && imageDelta === imageWidth) // ? found previous image
        );
        if (scrollThis) {
          const axisMultiplier = direction === 'up' ? -1 : 1;
          const pixels = imageWidth * axisMultiplier;
          scrollArea.scrollBy({
            left: pixels,
            behavior: 'smooth'
          });
          break;
        }
      }
      if (scrollThis === false) {
        this.scrollHorizontal(direction, scrollArea, freeScrollOffset);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  scrollVertical(scrollType, scrollArea) {
    const div = scrollArea;
    if (Helpers.isDefined(div) && div !== null) {
      const divHeight = div.offsetHeight; // ? includes paddings and margins
      const { verticalScrollHeight } = globals.config;

      let pixels = 0;
      pixels = scrollType.indexOf('page-') >= 0 ? divHeight : verticalScrollHeight;
      pixels = scrollType.indexOf('up') >= 0 ? -(pixels) : pixels;

      div.scrollBy({
        top: pixels,
        behavior: 'smooth'
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  scrollHorizontal(scrollType, scrollArea, offset) {
    if (Helpers.isDefined(scrollArea) && scrollArea !== null) {
      let areaWidth = scrollArea.offsetWidth; // ? includes paddings and margins
      areaWidth -= offset;
      const pixels = scrollType.indexOf('up') >= 0 ? -(areaWidth) : areaWidth;
      scrollArea.scrollBy({
        left: pixels,
        behavior: 'smooth'
      });
    }
  }

  focusSearchInput() {
    this.refSearchBox.current.focusInput();
  }

  focusFilterInput() {
    this.refFilterBox.current.focusInput();
  }

  handleModeSelect(activeModeKey) {
    // keep a record of the previous mode's available sources.
    const prevModeLastSourcesObjKey = `prev_${this.state.activeModeTab}_sources`;
    this[prevModeLastSourcesObjKey] = this.state.sourcesList;

    // get this mode's last available sources, set to defaults if absent.
    let sources = [];
    const thisModeLastSources = this[`prev_${activeModeKey}_sources`];
    if (Helpers.isArrayFull(thisModeLastSources)) {
      sources = thisModeLastSources;
    }
    /**/

    // keep a record of the previous mode's last selected source.
    const prevModeLastSourceObjKey = `prev_${this.state.activeModeTab}_source`;
    this[prevModeLastSourceObjKey] = this.state.activeSourceTab;

    // get this mode's last selected source, set to default if absent.
    let source = '';
    const thisModeLastSource = this[`prev_${activeModeKey}_source`];
    if (Helpers.isStringSet(thisModeLastSource)) {
      source = thisModeLastSource;
    } else {
      // none found in memory; set to default
      const searchText = this.getSearchText();
      source = Helpers.validSourcesObjArray(activeModeKey, searchText, true)[0].key;
    }
    /**/

    // ? auto navigates to mode and source.
    // ! each setState calls render()
    this.setState({
      activeModeTab: activeModeKey,
      activeSourceTab: source,
      sourcesList: sources
    }, () => { // ? re-render callback
      const modeData = this.state[`${activeModeKey}Data`].results;
      const gotModeData = Helpers.isObjectSet(modeData) && Helpers.objHasData(modeData);
      if (!gotModeData) {
        this.performSearch();
      }
    });
  }

  handleSourceSelect(activeSourceKey) {
    // ! each setState calls render()
    Helpers.removeAllSelection();
    this.focusSearchInput();
    this.setState({ activeSourceTab: activeSourceKey });
    this.scrollToStart('.right-side .scrollable-div');
  }

  jumpToMode(mode) {
    if (this.state.loading === true) {
      return null;
    }

    const { activeModeTab } = this.state;
    const modesObjArray = Helpers.validModesObjArray();
    const modesArray = Helpers.convertObjectArrayToSimpleArray(modesObjArray, 'key');
    const currentIx = modesArray.findIndex(key => key === activeModeTab);
    const maxIndex = modesArray.length - 1;

    let targetIx = 0;
    if (mode === 'next') {
      targetIx = currentIx + 1;
      if (targetIx > maxIndex) {
        targetIx = 0;
      }
      this.handleModeSelect(modesArray[targetIx]);
    } else if (mode === 'prev') {
      targetIx = currentIx - 1;
      if (targetIx < 0) {
        targetIx = maxIndex;
      }
      this.handleModeSelect(modesArray[targetIx]);
    } else {
      this.handleModeSelect(mode);
    }
  }

  jumpToSource(source) { /* accepts: 'prev', 'next', or '[key]' */
    if (this.state.loading === true) {
      return null;
    }

    const { activeModeTab } = this.state;
    const { activeSourceTab } = this.state;
    const sourcesObjArray = this.state.sourcesList;

    // images exception
    if (activeModeTab === 'images') {
      const direction = source === 'next' ? 'down' : 'up';
      this.scrollToImage(direction);
      return null;
    }

    Helpers.removeAllSelection();
    this.focusSearchInput();
    this.scrollToStart('.right-side .scrollable-div');

    if (Helpers.isArrayFull(sourcesObjArray)) {
      const sourcesArray = Helpers.convertObjectArrayToSimpleArray(sourcesObjArray, 'key');
      const currentIx = sourcesArray.findIndex(key => key === activeSourceTab);
      const maxIndex = sourcesArray.length - 1;
      let targetIx = 0;

      if (source === 'next') {
        targetIx = currentIx + 1;
        if (targetIx > maxIndex) {
          targetIx = 0;
        }
      } else if (source === 'prev') {
        targetIx = currentIx - 1;
        if (targetIx < 0) {
          targetIx = maxIndex;
        }
      }

      // ! each setState calls render()
      this.setState({
        activeSourceTab: sourcesArray[targetIx]
      });
    }
  }

  pronounceWord(word) {
    const pronounceText = Helpers.isStringSet(word)
      ? word.trim()
      : this.getSearchText();

    Pronounce(pronounceText);
  }

  tipClick(opName) {
    if (opName === 'close') {
      Helpers.removeAllSelection();
      this.focusSearchInput();
    } else {
      const selectionText = window.getSelection().toString();
      this[opName](selectionText);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  // selectPrevWord(e) {
  //   e.preventDefault();
  //   Helpers.selectNextSiblingText();
  // }

  // eslint-disable-next-line class-methods-use-this
  // selectNextWord(e) {
  //   e.preventDefault();
  //   Helpers.selectNextSiblingText();
  // }

  searchSelectedWord(word) {
    this.performSearch(Helpers.sanitizeSelectionText(word, 'string', true));
  }

  performSearch(word) {
    // ? define search text
    const searchText = Helpers.isStringSet(word)
      ? word
      : this.getSearchText();

    // ? reset searchbox text value after sanitized / trimmed
    this.refSearchBox.current.setValue(searchText);

    // ? search only for inputs that are at least 3 characters long
    if (searchText.length < globals.config.minInputLength) {
      return null;
    }

    // * TOTAL CLEAN-UP OF RIGHT-PANE RESULTS CONTENT
    // * WHEN A NEW WORD IS BEING SEARCHED.
    const newWord = Helpers.isStringSet(this.previousWord)
      && this.previousWord !== searchText;
    if (newWord) {
      // ? clean up state data
      // ! each setState calls render()
      this.setState({
        definitionsData: {},
        webData: {},
        exploreData: {},
        examplesData: {},
        imagesData: {},
        translatorData: {},
        filterText: '',
        sourcesList: [],
        loading: true
      });

      // ? reset filter box
      this.refFilterBox.current.setValue('');

      // ? clean up saved source tabs for the previous search
      const modesList = Helpers.validModesObjArray();
      const modeKeys = Helpers.convertObjectArrayToSimpleArray(modesList, 'key');
      for (const modeKey of modeKeys) {
        this[`prev_${modeKey}_sources`] = [];
      }
    } else {
      // ! each setState calls render()
      this.setState({ loading: true });
    }
    this.previousWord = searchText;
    // * -- CLEAN-UP DONE

    const normResults = {};
    const { activeModeTab } = this.state;

    // * GET DATA
    Fetch.AllResources(activeModeTab, searchText)
      .then((rawResults) => { // handles promise resolve
        // * Normalize raw data schema to my standard.
        normResults.searchText = searchText; // pass searchText to component.

        switch (activeModeTab) {
          case 'definitions':
            normResults.results = NormalizeDefinitions(searchText, rawResults);
            break;
          case 'web':
            normResults.results = NormalizeWebSearch(searchText, rawResults);
            break;
          case 'explore':
            normResults.results = NormalizeExplore(searchText, rawResults);
            break;
          case 'examples':
            normResults.results = NormalizeExamples(searchText, rawResults);
            break;
          case 'translator':
            normResults.results = NormalizeTranslator(searchText, rawResults);
            break;
          case 'images':
            normResults.results = NormalizeImages(searchText, rawResults);
            break;
          default:
            break;
        }

        // * DYNAMIC SOURCE TABS
        const allSourceResults = normResults.results;
        const sourcesObjArray = clone(Helpers.validSourcesObjArray(activeModeTab, searchText, true));

        for (const keyName of Object.keys(allSourceResults)) { // {mw: [{},{}], oxford: [{},{}], ..]
          const sourceResults = allSourceResults[keyName];
          const gotSourceResults = Helpers.isArrayFull(sourceResults);
          const sourceResultsCount = gotSourceResults ? sourceResults.length : 0;

          for (const [ix, sourceObj] of sourcesObjArray.entries()) {
            if (sourceObj.key === keyName) {
              if (sourceResultsCount === 0) {
                sourcesObjArray.splice(ix, 1); // ? remove from array
              } else {
                sourcesObjArray[ix].count = sourceResultsCount;
              }
            }
          }
        }

        //* ADD TO HISTORY (LocalStorage)
        let newHistoryData = [];
        const newHistoryObject = { timeStamp: new Date(), text: searchText };
        const historyData = Storage.getStorageObjValue('historyData');

        if (Helpers.isObjUseful(historyData)
          && Helpers.isArrayFull(historyData)) {
          // ? add last searched text to first pos.
          newHistoryData = [newHistoryObject, ...historyData];

          // ? keep list unique and time of search updated.
          newHistoryData = newHistoryData.reduce((newArray, item) => {
            let uniquesArray = [];
            const itemExists = newArray.filter(x => x.text === item.text).length >= 1;
            if (itemExists) {
              newArray.timeStamp = new Date();
              uniquesArray = newArray;
            } else {
              uniquesArray = [...newArray, item];
            }
            return uniquesArray;
          }, []);
        } else {
          newHistoryData.push(newHistoryObject);
        }

        // ? save to local storage.
        Storage.setStorageObjValue('historyData', newHistoryData);

        // * RENDER RESULTS
        // ! each setState calls render()
        this.renderingResults = true;
        this.setState({
          loading: false,
          [`${activeModeTab}Data`]: normResults,
          sourcesList: sourcesObjArray
        });

        // * AUTO-PICK DEFAULT SOURCE
        if (sourcesObjArray.length > 0) {
          const { activeSourceTab } = this.state;
          const gotResultsForActiveSource = sourcesObjArray.find(x => x.key === activeSourceTab);
          if (Helpers.isObjectSet(gotResultsForActiveSource) === false) {
            // ! each setState calls render()
            this.setState({
              activeSourceTab: sourcesObjArray[0].key
            });
          }
        }
      });
  }

  render() {
    console.log('rendering Body', { state: this.state });

    const { activeModeTab } = this.state;
    const { activeSourceTab } = this.state;
    const { definitionsData } = this.state;
    const { webData } = this.state;
    const { exploreData } = this.state;
    const { examplesData } = this.state;
    const { imagesData } = this.state;
    const { translatorData } = this.state;
    const { filterText } = this.state;
    const { sourcesList } = this.state;
    const { itemsCount } = this.state;
    const { loading } = this.state;
    const { showDrawer } = this.state;
    // const { showAboutModal } = this.state;
    const { renderingResults } = this;

    const searchText = this.getSearchText();
    const gotSearchText = searchText.length >= globals.config.minInputLength;

    // ? build loading text
    const modesObjArray = Helpers.validModesObjArray();
    const modeObj = modesObjArray.filter(x => x.key === activeModeTab)[0];
    const loadingText = modeObj.value;

    // ? conditional rendering
    const searchedDefinitions = Helpers.isObjectSet(definitionsData.results) && activeModeTab === 'definitions';
    const searchedWeb = Helpers.isObjectSet(webData.results) && activeModeTab === 'web';
    const searchedExplore = Helpers.isObjectSet(exploreData.results) && activeModeTab === 'explore';
    const searchedExamples = Helpers.isObjectSet(examplesData.results) && activeModeTab === 'examples';
    const searchedImages = Helpers.isObjectSet(imagesData.results) && activeModeTab === 'images';
    const searchedTranslation = Helpers.isObjectSet(translatorData.results) && activeModeTab === 'translator';
    const performedSearch = searchedDefinitions || searchedWeb || searchedExplore || searchedExamples || searchedImages || searchedTranslation;

    const gotDefinitions = searchedDefinitions && Helpers.objHasData(definitionsData.results);
    const gotWebSearch = searchedWeb && Helpers.objHasData(webData.results);
    const gotExplore = searchedExplore && Helpers.objHasData(exploreData.results);
    const gotExamples = searchedExamples && Helpers.objHasData(examplesData.results);
    const gotImages = searchedImages && Helpers.objHasData(imagesData.results);
    const gotTranslation = searchedTranslation && Helpers.objHasData(translatorData.results);
    const gotData = gotDefinitions || gotWebSearch || gotExplore || gotExamples || gotImages || gotTranslation;

    const doneLoading = gotSearchText && performedSearch && !loading;
    const contentReady = doneLoading && gotData;
    const renderNoResults = doneLoading && renderingResults && !gotData;
    this.renderingResults = false;

    const renderSourceTabs = contentReady;
    const renderResultsTitlePanel = contentReady;
    const renderSearchHistory = true;
    const renderDefinitions = contentReady && activeModeTab === 'definitions';
    const renderWebSearch = contentReady && activeModeTab === 'web';
    const renderExplore = contentReady && activeModeTab === 'explore';
    const renderExamples = contentReady && activeModeTab === 'examples';
    const renderImages = contentReady && activeModeTab === 'images';
    const renderTranslator = contentReady && activeModeTab === 'translator';
    const renderWhitespaceHolder = !renderDefinitions && !renderWebSearch && !renderExplore
      && !renderExamples && !renderImages && !renderTranslator && !renderNoResults;
    const showFilterBox = contentReady && !renderImages;

    // ? assign hot keys
    const searchInputHotKeyHandlers = {
      enter: () => {
        this.performSearch();
      }
    };
    const filterInputHotKeyHandlers = {
      enter: () => {
        this.filterResults();
      }
    };
    const inputsKeyMap = {
      enter: ['return']
    };

    return (
      <Fragment>
        <FlexboxGrid className="body-grid">
          <FlexboxGrid.Item colspan={7} className="left-side">
            <HotKeys
              keyMap={inputsKeyMap}
              handlers={searchInputHotKeyHandlers}
            >
              <SearchBox
                ref={this.refSearchBox}
                inputRefToBody={this.searchInputRefToBody}
                searchClicked={this.handleSearchClick()}
                loading={loading}
              />
            </HotKeys>
            <HotKeys
              keyMap={inputsKeyMap}
              handlers={filterInputHotKeyHandlers}
            >
              <FilterBox
                disabled={!showFilterBox}
                ref={this.refFilterBox}
                inputRefToBody={this.filterInputRefToBody}
                filterClicked={this.handleFilterClick()}
                loading={loading}
              />
            </HotKeys>
            {
              renderSearchHistory
              && (
                <Fragment>
                  <Panel className="history-title-panel">
                    <span>Search History</span>
                  </Panel>
                  <div
                    id="search-history"
                    className="scrollable-div scroll-vertical"
                    ref={this.refPopover2}
                  >
                    <SearchHistory
                      wordClickHandler={this.onWordClick()}
                    />
                  </div>
                </Fragment>
              )
            }
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={17} className="right-side">
            <ModeTabs
              appearance="subtle"
              activeTab={activeModeTab}
              modeSelected={this.handleModeSelect}
              loading={loading}
            />
            <div className="vertical-spacer-div" />
            {
              renderNoResults
              && (
                <Fragment>
                  <NoResultsTitlePanel
                    searchText={searchText}
                  />
                  <div
                    id="no-results"
                    className="scrollable-div scroll-vertical"
                    ref={this.refPopover}
                  >
                    <NoResults
                      searchText={searchText}
                      filterText={filterText}
                      wordClickHandler={this.onWordClick()}
                    />
                  </div>
                </Fragment>
              )
            }
            {
              renderSourceTabs
              && (
                <SourceTabs
                  appearance="default"
                  activeSourceTab={activeSourceTab}
                  sourceSelected={this.handleSourceSelect}
                  sourcesObjArray={sourcesList}
                />
              )
            }
            {
              renderResultsTitlePanel
              && (
                <ResultsTitlePanel
                  searchText={searchText}
                  filterText={filterText}
                  sourcesList={sourcesList}
                  itemsCount={itemsCount}
                  activeSourceTab={activeSourceTab}
                  wordClickHandler={this.onWordClick()}
                />
              )
            }
            {
              renderDefinitions
              && (
                <div
                  id="definitions"
                  className="scrollable-div scroll-vertical"
                  ref={this.refPopover}
                >
                  <Definitions
                    // activeModeTab={activeModeTab}
                    activeSourceTab={activeSourceTab}
                    data={definitionsData}
                    filterText={filterText}
                    wordClickHandler={this.onWordClick()}
                  />
                </div>
              )
            }
            {
              renderWebSearch
              && (
                <div
                  id="web"
                  className="scrollable-div scroll-vertical"
                  ref={this.refPopover}
                >
                  <WebSearch
                    // activeModeTab={activeModeTab}
                    activeSourceTab={activeSourceTab}
                    data={webData}
                    filterText={filterText}
                    wordClickHandler={this.onWordClick()}
                  />
                </div>
              )
            }
            {
              renderExplore
              && (
                <div
                  id="explore"
                  className="scrollable-div scroll-vertical"
                  ref={this.refPopover}
                >
                  <Explore
                    activeModeTab={activeModeTab}
                    activeSourceTab={activeSourceTab}
                    data={exploreData}
                    filterText={filterText}
                    wordClickHandler={this.onWordClick()}
                  />
                </div>
              )
            }
            {
              renderExamples
              && (
                <div
                  id="examples"
                  className="scrollable-div scroll-vertical"
                  ref={this.refPopover}
                >
                  <Examples
                    activeModeTab={activeModeTab}
                    activeSourceTab={activeSourceTab}
                    data={examplesData}
                    filterText={filterText}
                    wordClickHandler={this.onWordClick()}
                  />
                </div>
              )
            }
            {
              renderImages
              && (
                <Images
                  activeModeTab={activeModeTab}
                  activeSourceTab={activeSourceTab}
                  data={imagesData}
                  imageClickHandler={this.onImageClick()}
                  mouseWheelHandler={this.onMouseWheel()}
                  prevImageClickHandler={this.prevImageClick()}
                  nextImageClickHandler={this.nextImageClick()}
                />
              )
            }
            {
              renderTranslator
              && (
                <div
                  id="translations"
                  className="scrollable-div scroll-vertical"
                  ref={this.refPopover}
                >
                  <Translator
                    activeModeTab={activeModeTab}
                    activeSourceTab={activeSourceTab}
                    data={translatorData}
                    filterText={filterText}
                    wordClickHandler={this.onWordClick()}
                  />
                </div>
              )
            }
            {
              renderWhitespaceHolder
              && (
                <Fragment>
                  {loading && (<LoadingText text={loadingText} />)}
                  {/* <LoadingText text={loadingText} /> */}
                  <WhitespaceHolder loading={loading} />
                </Fragment>
              )
            }
          </FlexboxGrid.Item>
        </FlexboxGrid>
        <Popover
          selectionRef={this.refPopover}
          className="popover highlightMenu highlightMenuActive"
          gap={7}
        >
          <div className="highlightMenuInner">
            <div className="buttonSet">
              <div
                role="presentation"
                onMouseDown={() => this.tipClick('pronounceWord')}
                className="button buttonHighlightMenu"
              >
                <Icon icon="speaker" />
              </div>
              <div
                role="presentation"
                onMouseDown={() => this.tipClick('searchSelectedWord')}
                className="button buttonHighlightMenu"
              >
                <Icon icon="search" />
              </div>
              <div className="buttonSetSeparator" />
              {/* <div
                role="presentation"
                onMouseDown={this.selectPrevWord}
                className="button buttonHighlightMenu"
              >
                <Icon icon="i-cursor" />
                <Icon icon="angle-right" />
              </div>
              <div
                role="presentation"
                onMouseDown={this.selectNextWord}
                className="button buttonHighlightMenu"
              >
                <Icon icon="angle-left" />
                <Icon icon="i-cursor" />
              </div>
              <div className="buttonSetSeparator" /> */}
              <div
                role="presentation"
                onMouseDown={() => this.tipClick('close')}
                className="button buttonHighlightMenu"
              >
                <Icon icon="close" />
              </div>
            </div>
          </div>
          <div className="highlightMenuArrowClip">
            <span className="highlightMenuArrow" />
          </div>
        </Popover>
        <Popover
          selectionRef={this.refPopover2}
          className="popover highlightMenu highlightMenuActive"
          gap={7}
        >
          <div className="highlightMenuInner">
            <div className="buttonSet">
              <div
                role="presentation"
                onMouseDown={() => this.tipClick('pronounceWord')}
                className="button buttonHighlightMenu"
              >
                <Icon icon="speaker" />
              </div>
              <div
                role="presentation"
                onMouseDown={() => this.tipClick('searchSelectedWord')}
                className="button buttonHighlightMenu"
              >
                <Icon icon="search" />
              </div>
              <div className="buttonSetSeparator" />
              <div
                role="presentation"
                onMouseDown={() => this.tipClick('close')}
                className="button buttonHighlightMenu"
              >
                <Icon icon="close" />
              </div>
            </div>
          </div>
          <div className="highlightMenuArrowClip">
            <span className="highlightMenuArrow" />
          </div>
        </Popover>
        <AboutModal
          ref={this.refAboutModal}
        />
        <Drawer
          size="xs"
          placement="right"
          show={showDrawer}
          backdrop
          onHide={this.closeDrawer}
        >
          <Drawer.Header>
            <Drawer.Title>Drawer Title</Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <div className="paragraph">
              <div className="text-block">
                <div className="text-row" style={{ width: '97%' }} />
                <div className="text-row" style={{ width: '100%' }} />
                <div className="text-row" style={{ width: '94%' }} />
                <div className="text-row" style={{ width: '90%' }} />
              </div>
            </div>
          </Drawer.Body>
          <Drawer.Footer>FOOTER GOES HERE</Drawer.Footer>
        </Drawer>
      </Fragment>
    );
  }
}
