import JSPath from 'jspath';
import { globals } from '../../globals';
import Helpers from '../../helpers';

const normalizePixabay = (props) => {
  const { sourceRawData } = props;
  const {
    imagesQ,
    urlQ,
    widthQ,
    heightQ,
    bigUrlQ,
    bigWidthQ,
    bigHeightQ,
    tagsQ
  } = props;

  // clean up examples - remove braces, etc.
  const imageObjectsArray = JSPath.apply(imagesQ, sourceRawData);
  const normData = [];
  for (const imageObject of imageObjectsArray) {
    const url = JSPath.apply(urlQ, imageObject);
    const width = JSPath.apply(widthQ, imageObject);
    const height = JSPath.apply(heightQ, imageObject);
    const bigUrl = JSPath.apply(bigUrlQ, imageObject);
    const bigWidth = JSPath.apply(bigWidthQ, imageObject);
    const bigHeight = JSPath.apply(bigHeightQ, imageObject);
    const tags = JSPath.apply(tagsQ, imageObject);

    // create normalized item entry
    const normItem = {
      url,
      width,
      height,
      bigUrl,
      bigWidth,
      bigHeight,
      tags
    };

    normData.push(normItem);
  }
  return normData; // string array
};

const NormalizeImages = (searchText, rawData) => {
  console.log('rendering NormalizeImages', searchText, rawData.length);

  const normData = {};
  const sourcesObjArray = Helpers.validSourcesObjArray('images', searchText, false);
  const sourceKeysArray = Helpers.convertObjectArrayToSimpleArray(sourcesObjArray, 'key');

  for (const source of sourceKeysArray) {
    const resObj = globals.images.sources[source];

    // Given source is unknown to this API, so skip it.
    if (Helpers.isObjectSet(resObj) === false) {
      normData[source] = [];
      break;
    }

    const { query } = resObj;
    const lvl0Q = query.lvl0; // Q for query
    const sourceRawData = JSPath.apply(lvl0Q, rawData);

    if (Helpers.isArraySet(sourceRawData)) {
      const resQueryProps = {
        sourceRawData, // (object array)
        imagesQ: query.images, // (object array)
        urlQ: query.url, // (string)
        widthQ: query.width, // (string)
        heightQ: query.height, // (string)
        bigUrlQ: query.bigUrl, // (string)
        bigWidthQ: query.bigWidth, // (string)
        bigHeightQ: query.bigHeight, // (string)
        tagsQ: query.tags // (string)
      };

      switch (source) {
        case 'pixabay':
          normData[source] = normalizePixabay(resQueryProps);
          break;
        default:
          break;
      }
    }
  }
  return normData;
};

export default NormalizeImages;
