import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

const WhitespaceHolder = (props) => {
  console.log('rendering WhitespaceHolder', props);

  const { loading } = props;
  const shimmerClass = loading ? 'shimmer' : '';

  const paragraphs = [];
  for (let ix = 1; ix <= 3; ix += 1) {
    paragraphs.push(
      <Fragment key={ix}>
        <div className="vertical-spacer-div" />
        <div className="paragraph">
          <div className="text-block">
            <div className="text-row">
              <div className="text-row-item p-title" />
              <div className={`text-row-item p1 ${shimmerClass}`} />
            </div>
            <div className="text-row">
              <div className="text-row-item p-title" />
              <div className={`text-row-item p2 ${shimmerClass}`} />
            </div>
            <div className="text-row">
              <div className="text-row-item p-title" />
              <div className={`text-row-item p3 ${shimmerClass}`} />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }

  // render items
  return (
    <Fragment>
      <div className="whitespace-holder-title-wrapper">
        {!loading && (`awaiting search...`)}
      </div>
      <div id="whitespace-holder">
        <div className="paragraph">
          <div className="text-block">
            <div className="circle" />
            <div>
              <div className="text-row">
                <div className="text-row-item title-1" />
              </div>
              <div className="text-row">
                <div className="text-row-item title-2" />
              </div>
            </div>
          </div>
        </div>
        {paragraphs}
      </div>
    </Fragment>
  );
};

WhitespaceHolder.propTypes = {
  loading: PropTypes.bool
};

export default WhitespaceHolder;
