import JSPath from 'jspath';
import { globals } from '../../globals';
import Helpers from '../../helpers';

const normalizeMerriamWebster = (props) => {
  const normData = [];

  const { sourceRawData } = props;
  const { lvl1Q } = props;
  const { posQ } = props;
  const { definitionQ } = props;
  const { examplesQ } = props;
  const { synonymsQ } = props;
  const { antonymsQ } = props;

  for (const sourceRawItem of sourceRawData) {
    // normalize raw data
    const partOfSpeech = JSPath.apply(posQ, sourceRawItem);

    // query level 0 array to reach level 1 array
    const lvl1A = JSPath.apply(lvl1Q, sourceRawItem);

    // lvl 1 array loop
    for (const lvl1Item of lvl1A) {
      // lvl 2 array loop
      for (const lvl2Item of lvl1Item) {
        // normalize raw data
        const dirtyDefinition = JSPath.apply(definitionQ, lvl2Item);
        if (Helpers.isStringSet(dirtyDefinition)) {
          const definition = Helpers.cleanText(dirtyDefinition);

          const dirtyExamples = JSPath.apply(examplesQ, lvl2Item);
          const synonyms = JSPath.apply(synonymsQ, lvl2Item);
          const antonyms = JSPath.apply(antonymsQ, lvl2Item);

          // clean up examples - remove braces, etc.
          const examples = [];
          for (const dirtyExample of dirtyExamples) {
            const cleanExample = Helpers.cleanText(dirtyExample);
            examples.push(cleanExample);
          }

          // create normalized item entry
          const normItem = {
            partOfSpeech,
            definition,
            examples,
            synonyms,
            antonyms
          };

          // append item to source array.
          normData.push(normItem);
        }
      }
    }
  }
  return normData;
};

const normalizeWordsApi = (props) => {
  const normData = [];

  const { sourceRawData } = props;
  const { posQ } = props;
  const { definitionQ } = props;
  const { examplesQ } = props;
  const { synonymsQ } = props;
  const { antonymsQ } = props;

  for (const sourceRawItem of sourceRawData) {
    // normalize raw data
    const partOfSpeech = JSPath.apply(posQ, sourceRawItem);
    const definition = JSPath.apply(definitionQ, sourceRawItem);
    if (Helpers.isStringSet(definition)) {
      const examples = JSPath.apply(examplesQ, sourceRawItem);
      const synonyms = JSPath.apply(synonymsQ, sourceRawItem);
      const antonyms = JSPath.apply(antonymsQ, sourceRawItem);

      // create normalized item entry
      const normItem = {
        partOfSpeech,
        definition,
        examples,
        synonyms,
        antonyms
      };

      normData.push(normItem);
    }
  }
  return normData;
};

const normalizeOwlBot = (props) => {
  const normData = [];

  const { sourceRawData } = props;
  const { posQ } = props;
  const { definitionQ } = props;
  const { examplesQ } = props;
  // const { synonymsQ } = props;
  // const { antonymsQ } = props;

  for (const sourceRawItem of sourceRawData) {
    // normalize raw data
    const partOfSpeech = JSPath.apply(posQ, sourceRawItem);
    const definition = JSPath.apply(definitionQ, sourceRawItem);
    if (Helpers.isStringSet(definition)) {
      const examples = JSPath.apply(examplesQ, sourceRawItem);
      // const synonyms = JSPath.apply(synonymsQ, sourceRawItem);
      // const antonyms = JSPath.apply(antonymsQ, sourceRawItem);

      // create normalized item entry
      const normItem = {
        partOfSpeech,
        definition,
        examples,
        // synonyms,
        // antonyms
      };

      normData.push(normItem);
    }
  }
  return normData;
}

const normalizeStands4Phrases = (props) => {
  const normData = [];

  const { sourceRawData } = props;
  // const { posQ } = props;
  const { definitionQ } = props;
  const { examplesQ } = props;
  // const { synonymsQ } = props;
  // const { antonymsQ } = props;

  for (const sourceRawItem of sourceRawData) {
    // normalize raw data
    // const partOfSpeech = JSPath.apply(posQ, sourceRawItem);
    const definition = JSPath.apply(definitionQ, sourceRawItem);
    if (Helpers.isStringSet(definition)) {
      const examples = JSPath.apply(examplesQ, sourceRawItem);
      // const synonyms = JSPath.apply(synonymsQ, sourceRawItem);
      // const antonyms = JSPath.apply(antonymsQ, sourceRawItem);

      // create normalized item entry
      const normItem = {
        // partOfSpeech,
        definition,
        examples,
        // synonyms,
        // antonyms
      };

      normData.push(normItem);
    }
  }
  return normData;
};

const normalizeUrban = (props) => {
  const normData = [];

  const { sourceRawData } = props;
  const { definitionQ } = props;
  const { examplesQ } = props;

  for (const sourceRawItem of sourceRawData) {
    // normalize definition raw data
    const dirtyDefinition = JSPath.apply(definitionQ, sourceRawItem);

    if (Helpers.isStringSet(dirtyDefinition)) {
      // clean up definition - remove square brackets, etc.
      const definition = Helpers.cleanText(dirtyDefinition);

      // normalize examples raw data
      const dirtyExamples = JSPath.apply(examplesQ, sourceRawItem);

      // clean up examples - remove square brackets, etc.
      const examples = [];
      for (const dirtyExample of dirtyExamples) {
        const cleanExample = Helpers.cleanText(dirtyExample);
        examples.push(cleanExample);
      }

      // create normalized item entry
      const normItem = {
        definition,
        examples
      };

      normData.push(normItem);
    }
  }
  return normData;
};

const normalizeWiki = (props) => {
  const normData = [];

  const { sourceRawData } = props;
  const { lvl1Q } = props;
  const { posQ } = props;
  const { definitionQ } = props;
  const { examplesQ } = props;

  for (const sourceRawItem of sourceRawData) {
    // normalize raw data
    const partOfSpeech = JSPath.apply(posQ, sourceRawItem);

    // query level 0 array to reach level 1 array
    const lvl1A = JSPath.apply(lvl1Q, sourceRawItem);

    // lvl 1 array loop
    for (const lvl1Item of lvl1A) {
      // normalize definition raw data
      const dirtyDefinition = JSPath.apply(definitionQ, lvl1Item);

      if (Helpers.isStringSet(dirtyDefinition)) {
        // clean up definition - remove html, etc.
        const definition = Helpers.cleanText(dirtyDefinition);

        // normalize examples raw data
        const dirtyExamples = JSPath.apply(examplesQ, lvl1Item);

        // clean up examples - remove html, etc.
        const examples = [];
        for (const dirtyExample of dirtyExamples) {
          const cleanExample = Helpers.cleanText(dirtyExample);
          examples.push(cleanExample);
        }

        // create normalized item entry
        const normItem = {
          partOfSpeech,
          definition,
          examples
        };

        normData.push(normItem);
      }
    }
  }
  return normData;
};

const normalizeOxford = (props) => {
  const normData = [];

  const { sourceRawData } = props;
  const { lvl1Q } = props;
  const { lvl2Q } = props;
  const { posQ } = props;
  const { definitionQ } = props;
  const { examplesQ } = props;
  // const { synonymsQ } = props;
  // const { synonymsInnerQ } = props;
  // const { antonymsQ } = props;
  // const { antonymsInnerQ } = props;
  // const { synsRawData } = props;
  // const { antsRawData } = props;

  for (const sourceRawItem of sourceRawData) {
    // query level 0 array to reach level 1 array
    const lvl1A = JSPath.apply(lvl1Q, sourceRawItem);
    for (const lvl1Item of lvl1A) {
      // normalize raw data
      const partOfSpeech = JSPath.apply(posQ, lvl1Item);

      // query level 1 array to reach level 2 object
      const lvl2A = JSPath.apply(lvl2Q, lvl1Item);

      for (const lvl2Item of lvl2A) {
        // normalize raw data
        const definition = JSPath.apply(definitionQ, lvl2Item);
        if (Helpers.isStringSet(definition)) {
          // examples
          const examples = JSPath.apply(examplesQ, lvl2Item);

          // synonyms
          // const synonymIds = JSPath.apply(synonymsQ, lvl2Item);
          // let synonyms = [];
          // for (const synonymId of synonymIds) {
          //   let synItemFilter = synonymsInnerQ.synItem;
          //   synItemFilter += synonymsInnerQ.synonyms;
          //   synItemFilter = synItemFilter.replace('{{sense_id}}', synonymId);

          //   // normalize raw data
          //   const synItem = JSPath.apply(synItemFilter, synsRawData);
          //   synonyms = [...synItem];
          // }

          // antonyms
          // const antonymIds = JSPath.apply(antonymsQ, lvl2Item);
          // let antonyms = [];
          // for (const antonymId of antonymIds) {
          //   let antItemFilter = antonymsInnerQ.antItem;
          //   antItemFilter += antonymsInnerQ.antonyms;
          //   antItemFilter = antItemFilter.replace('{{sense_id}}', antonymId);

          //   // normalize raw data
          //   const antItem = JSPath.apply(antItemFilter, antsRawData);
          //   antonyms = [...antItem];
          // }

          // create normalized item entry
          const normItem = {
            partOfSpeech,
            definition,
            examples
            // synonyms,
            // antonyms
          };

          normData.push(normItem);
        }
      }
    }
  }
  return normData;
};

const NormalizeDefinitions = (searchText, rawData) => {
  console.log('rendering NormalizeDefinitions', searchText, rawData.length);

  const normData = {};
  const sourcesObjArray = Helpers.validSourcesObjArray('definitions', searchText, false);
  const sourceKeysArray = Helpers.convertObjectArrayToSimpleArray(sourcesObjArray, 'key');

  for (const source of sourceKeysArray) {
    const resObj = globals.definitions.sources[source];

    // Given source is unknown to this API, so skip it.
    if (Helpers.isObjectSet(resObj) === false) {
      normData[source] = [];
      break;
    }

    const { query } = resObj;
    let lvl0Q = query.lvl0; // Q for query
    if (Helpers.isObjValid(query.lvl0Filter)) {
      lvl0Q += query.lvl0Filter.replace(/{{searchText}}/g, searchText);
    }
    const sourceRawData = JSPath.apply(lvl0Q, rawData);

    if (Helpers.isArraySet(sourceRawData)) {
      const resQueryProps = {
        sourceRawData, // (object array)
        lvl1Q: query.lvl1, // Q for query (object array)
        lvl2Q: query.lvl2, // (object array)
        posQ: query.pos, // part of speech query (string)
        definitionQ: query.definition, // definition query (string)
        examplesQ: query.examples, // examples query (string array)
        synonymsQ: query.synonyms, // synonyms query (string array)
        antonymsQ: query.antonyms // antonyms query (string array)
      };

      // const oxfordSynsQ = globals.definitions.sources.oxford_syns.query;
      // const oxfordAntsQ = globals.definitions.sources.oxford_ants.query;

      switch (source) {
        case 'mw':
          normData[source] = normalizeMerriamWebster(resQueryProps);
          break;
        case 'words':
          normData[source] = normalizeWordsApi(resQueryProps);
          break;
        case 'stands4_phrases':
          normData[source] = normalizeStands4Phrases(resQueryProps);
          break;
        case 'urban':
          normData[source] = normalizeUrban(resQueryProps);
          break;
        case 'wiki':
          normData[source] = normalizeWiki(resQueryProps);
          break;
        case 'oxford':
          // resQueryProps.synonymsInnerQ = oxfordSynsQ;
          // resQueryProps.antonymsInnerQ = oxfordAntsQ;
          // resQueryProps.synsRawData = JSPath.apply(oxfordSynsQ.lvl0, rawData);
          // resQueryProps.antsRawData = JSPath.apply(oxfordAntsQ.lvl0, rawData);
          normData[source] = normalizeOxford(resQueryProps);
          break;
        case 'owlbot':
          normData[source] = normalizeOwlBot(resQueryProps);
        default:
          break;
      }
    }
  }
  return normData;
};

export default NormalizeDefinitions;
