import React from 'react';
import PropTypes from 'prop-types';
import AnimateText from 'react-pose-text';
import { globals } from '../globals';

const LoadingText = (props) => {
  console.log('rendering LoadingText', props);

  const { text } = props;

  const axiosTimeout = globals.axios.baseReq.timeout / 1000;
  let loadingDots = '';
  for (let secs = 1; secs <= axiosTimeout; secs += 1) {
    loadingDots += 'ㅇㅇ'; /* • */
  }
  console.log(axiosTimeout, loadingDots);

  return (
    <div className="animate-loading-wrapper">
      <AnimateText
        initialPose="exit"
        pose="enter"
        charPoses={globals.config.charPoses}
      >
        {`loading ${text}`}
      </AnimateText>
      <div className="animate-loading-dots-wrapper">
        <AnimateText
          initialPose="exit"
          pose="enter"
          charPoses={globals.config.dotPoses}
        >
          {loadingDots}
        </AnimateText>
      </div>
    </div>
  );
};

LoadingText.propTypes = {
  text: PropTypes.string
};

export default LoadingText;
