import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helpers from '../../helpers';
import WordElements from '../FormatHelpers/WordElements';

const splitInWordsRtrnElems = (params) => {
  const {
    rawText,
    searchText,
    hasFilter,
    filterText,
    wordClickHandler
  } = params;

  return (
    <WordElements
      doTxtSelectFormatting
      data={rawText}
      searchText={searchText}
      hasFilter={hasFilter}
      filterText={filterText}
      wordClickHandler={wordClickHandler}
    />
  );
};

const ViewFormat = (props) => {
  // console.log('rendering ViewFormat', props);

  const { searchText } = props;
  const { hasFilter } = props;
  const { filterText } = props;

  const { source } = props;
  const { title } = props;
  const { snippet } = props;
  const { link } = props;

  const { wordClickHandler } = props;

  const elemParams = {
    hasFilter,
    filterText,
    searchText,
    wordClickHandler
  };

  // title
  let titleComp = '';
  if (Helpers.isStringSet(title)) {
    // elemParams.rawText = title;
    // const titleHtml = splitInWordsRtrnElems(elemParams);
    titleComp = (
      <div className="new-line">
        <div>
          <a href={link} target="_blank">{title}</a>
        </div>
      </div>
    );
  }

  // snippet
  let snippetComp = '';
  if (Helpers.isStringSet(snippet)) {
    elemParams.rawText = snippet;
    const snippetHtml = splitInWordsRtrnElems(elemParams);
    snippetComp = (
      <div className="new-line">
        <div>
          {snippetHtml}
        </div>
      </div>
    );
  }

  // source
  let sourceComp = '';
  if (Helpers.isStringSet(source)) {
    // elemParams.rawText = source;
    // const sourceHtml = splitInWordsRtrnElems(elemParams);
    sourceComp = (
      <div className="new-line">
        <div className="source">
          {source}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {titleComp}
      {snippetComp}
      {sourceComp}
    </Fragment>
  );
};

ViewFormat.propTypes = {
  searchText: PropTypes.string,
  hasFilter: PropTypes.bool,
  filterText: PropTypes.string,
  source: PropTypes.any,
  title: PropTypes.any,
  snippet: PropTypes.any,
  link: PropTypes.any,
  wordClickHandler: PropTypes.func
};

export default ViewFormat;
