import JSPath from 'jspath';
import { globals } from '../../globals';
import Helpers from '../../helpers';

const normalizeGoogleTranslate = (props) => {
  const { sourceRawData } = props;
  const { translatedTextQ } = props;
  const { source } = props;
  const language = globals.translator.sources.list.filter(x => x.key === source)[0].description;
  const translatedText = JSPath.apply(translatedTextQ, sourceRawData);
  return (language + ' - ' + translatedText); // string
};

const NormalizeTranslator = (searchText, rawData) => {
  console.log('rendering NormalizeTranslator', searchText, rawData.length);

  const normData = {};
  const sourcesObjArray = Helpers.validSourcesObjArray('translator', searchText, false);
  const sourceKeysArray = Helpers.convertObjectArrayToSimpleArray(sourcesObjArray, 'key');

  for (const source of sourceKeysArray) {
    const resObj = globals.translator.sources[source];

    //! Given source is unknown to this API, so skip it.
    if (Helpers.isObjectSet(resObj) === false) {
      normData[source] = [];
      break;
    }

    const { query } = resObj;
    let lvl0Q = query.lvl0; // Q for query
    if (Helpers.isObjValid(query.lvl0Filter)) {
      lvl0Q += query.lvl0Filter.replace(/{{searchText}}/g, searchText);
    }

    const sourceRawData = JSPath.apply(lvl0Q, rawData);

    if (Helpers.isArraySet(sourceRawData)) {
        const resQueryProps = {
            source,
            searchText,
            sourceRawData, // (object array)
        };

        resQueryProps.translatedTextQ = query.translatedText;

        switch (source) {
            case 'google_translate_tr':
                if (Helpers.isArrayFull(normData[source])) {
                    normData[source] = normData[source].concat([normalizeGoogleTranslate(resQueryProps)]);
                } else {
                    normData[source] = [normalizeGoogleTranslate(resQueryProps)]; //! needs to be an array!
                }
                break;
            default:
                // ! append all other data to ES results set for grouping Translations.
                normData[source] = [normalizeGoogleTranslate(resQueryProps)];
                if (Helpers.isArrayFull(normData.google_translate_tr)) {
                    normData.google_translate_tr = normData.google_translate_tr.concat(normData[source]);
                } else {
                    normData.google_translate_tr = normData[source];
                }
                break;
        }
    }
  }
  return normData;
};

export default NormalizeTranslator;
