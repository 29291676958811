import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'rsuite';
import Helpers from '../helpers';

const SourceTabs = (props) => {
  const {
    appearance,
    activeSourceTab,
    sourceSelected,
    sourcesObjArray
  } = props;

  console.log('rendering SourceTabs', props);

  const navItems = [];
  for (const sourceObj of sourcesObjArray) {
    if (sourceObj.display) {
      navItems.push(
        <Nav.Item
          key={sourceObj.key}
          eventKey={sourceObj.key}
        >
          {sourceObj.value}
        </Nav.Item>
      );
    }
  }

  // render items
  if (Helpers.isArrayFull(navItems)) {
    return (
      <Nav
        appearance={appearance}
        activeKey={activeSourceTab}
        onSelect={sourceSelected}
      >
        {navItems}
      </Nav>
    );
  }
  return ('');
};

SourceTabs.propTypes = {
  activeModeTab: PropTypes.string,
  activeSourceTab: PropTypes.string,
  sourceSelected: PropTypes.func
};

export default SourceTabs;
