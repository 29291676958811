import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'rsuite';
import { globals } from '../globals';
import Helpers from '../helpers';

const ModeTabs = (props) => {
  console.log('rendering ModeTabs', props);

  const {
    appearance,
    activeTab,
    modeSelected,
    loading
  } = props;

  // fetch for modes
  const modesObjArray = Helpers.validModesObjArray();

  // additional options as dynamic props
  const navItemOptions = {};
  if (loading) {
    navItemOptions.disabled = 'disabled';
  }

  const navItems = [];
  for (const modeObj of modesObjArray) {
    navItems.push(
      <Nav.Item
        key={modeObj.key}
        eventKey={modeObj.key}
        {...navItemOptions}
      >
        {modeObj.value}
      </Nav.Item>
    );
  }

  // render items
  return (
    <Nav
      appearance={appearance}
      activeKey={activeTab}
      onSelect={modeSelected}
    >
      {navItems}
    </Nav>
  );
};

ModeTabs.propTypes = {
  loading: PropTypes.bool,
  appearance: PropTypes.string,
  activeTab: PropTypes.string,
  modeSelected: PropTypes.func
};

export default ModeTabs;
