import React from 'react';

const escBtnPath = ''
  + 'M21,5H3C1.896,5,1,5.896,1,7v10c0,1.104,0.896,2,2,2h18c1.104,0,2-0.896,2-2V7C23,5.896,22.104,5,21,'
  + '5z M9.779,14.805H5.967 V9.117h3.805v0.949H7.139v1.355h2.25v0.918h-2.25v1.523h2.641V14.805z M12.002,14.883c-1.011,'
  + '0-1.824-0.584-1.824-1.422h1.07 c0,0.408,0.355,0.621,0.777,0.621c0.324,0,0.656-0.121,'
  + '0.656-0.445c0-0.752-2.371-0.309-2.371-1.82c0-0.417,0.307-1.316,1.688-1.316 c1.625,0,1.77,1.125,1.77,'
  + '1.363h-1.129c-0.043-0.316-0.149-0.559-0.645-0.559c-0.313,0-0.598,0.144-0.598,0.457 c0,0.806,2.383,0.199,2.383,'
  + '1.828C13.779,14.447,12.905,14.883,12.002,14.883z M16.264,13.973c0.667,0,0.708-0.532,0.711-0.629 h1.059c0,'
  + '0.505-0.413,1.539-1.75,1.539c-0.627,0-1.953-0.281-1.953-2.133v-0.109c0-0.3,0.067-2.141,1.945-2.141 c1.626,0,'
  + '1.755,1.399,1.758,1.652h-1.059c-0.003-0.102-0.051-0.738-0.719-0.738c-0.164,0-0.797-0.043-0.797,1.227v0.109 C15.459,'
  + '12.911,15.382,13.973,16.264,13.973z';

const prevBtnPath = ''
  + 'M19.214 21.212L12.865 15l6.35-6.35-1.933-1.932L9 15l8.282 8.282 1.932-2.07z';

const nextBtnPath = ''
  + 'M11 21.212L17.35 15 11 8.65l1.932-1.932L21.215 15l-8.282 8.282L11 21.212z';

export default class SvgLib {
  static escBtnString() {
    return `
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="34px"
        height="34px"
        class="hotkey-esc-btn"
      >
        <path d="${escBtnPath}" />
      </svg>
    `;
  }

  static escBtnJsx() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="34px"
        height="34px"
        className="hotkey-esc-btn"
      >
        <path d={escBtnPath} />
      </svg>
    );
  }

  static prevImageBtnJsx() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        className="image-gallery-navbtn"
      >
        <path
          d={prevBtnPath}
          className="image-gallery-btn-svg-path"
        />
      </svg>
    );
  }

  static nextImageBtnJsx() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 30 30"
        className="image-gallery-navbtn"
      >
        <path
          d={nextBtnPath}
          className="image-gallery-btn-svg-path"
        />
      </svg>
    );
  }

  static okBadgeIconJsx() {
    return (
      <svg
        className="ok-badge-icon"
        width="24"
        height="24"
        xmlns="http://www.w3.org/2000/svg"
        xlinkHref="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path d="M0 0h24v24H0z" />
        </defs>
        <g fill="none" fillRule="evenodd">
          <mask fill="#FFF">
            <use xlinkHref="#a" />
          </mask>
          <use fill="#FFF" xlinkHref="#a" />
          <g mask="url(#b)">
            <path
              className="ok-badge-icon-fill"
              d="M10.234 21.356l-4.333.938-1.354-4.222-3.922-2.066L2.301 11.9.625 7.797l3.922-2.066
                1.354-4.222 4.333.938L13.985.084l2.716 3.504 4.393.6-.171 4.43 2.978 3.283-2.978
                3.284.171 4.43-4.393.6-2.716 3.504z"
            />
            <g fill="#FFF">
              <path d="M6 12.778l2.121-2.121 4.243 4.242-2.121 2.122z" />
              <path d="M8.121 14.9L15.9 7.12l2.122 2.122-7.778 7.778z" />
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
