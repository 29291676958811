import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import ClickToSelect from '@mapbox/react-click-to-select';
import uuidv4 from 'uuid/dist/v4';
import Helpers from '../../helpers';

const markedElement = (wordsArray, text, className) => wordsArray.reduce((prev, current, i) => {
  if (i === 0) {
    return [current]; // just text
  }
  const comp = <span key={uuidv4()} className={className}>{text}</span>;
  return prev.concat(comp, current);
}, []);

const WordElements = (props) => {
  const {
    data,
    searchText,
    hasFilter,
    filterText,
    wordClickHandler,
    doTxtSelectFormatting,
    newLine
  } = props;

  let dataArray = [];

  if (Helpers.isStringSet(data)) {
    // ? split text into a `words` array using `space` as the condish.
    const spaceRegex = new RegExp(' ', 'g'); // global
    const spacedArray = data.trim().split(spaceRegex);

    // ? rebuild array to ignore space from search and filter words (eg: `take down`)
    // ? regorganizes instances of [..., 'phrase', 'word', ...] to => [...,'phrase word', ...]
    // ? applies to both `search` and `filter` words - filter takes precedence.
    const numOfSpaces = hasFilter
      ? filterText.split(spaceRegex).length - 1
      : searchText.split(spaceRegex).length - 1;

    for (let i = 0; i < spacedArray.length; i += 1) {
      let fixed = false;
      if (i >= numOfSpaces) {
        let spacedText = ``;
        for (let x = numOfSpaces; x >= 0; x -= 1) {
          spacedText += `${spacedArray[i - x]}`;
          spacedText += x > 0 ? ` ` : ``;
        }
        const cleanSpacedText = Helpers.cleanInput(spacedText);
        const inputText = hasFilter ? filterText : searchText;
        if (cleanSpacedText === inputText) {
          dataArray.splice((dataArray.length) - numOfSpaces, numOfSpaces);
          dataArray.push(spacedText);
          fixed = true;
        }
      }
      if (fixed === false) {
        dataArray.push(spacedArray[i]);
      }
    }

    // ? add space to each array item's end
    for (let i = 0; i < dataArray.length - 1; i += 1) {
      dataArray[i] += ' ';
    }
  } else if (Helpers.isArraySet(data)) {
    dataArray = data;
  }

  const normalizeSelection = () => {
    const selection = window.getSelection();
    const rawSelectionText = selection.toString();

    // ? modify the selection (just for text nodes)
    const ranges = Helpers.sanitizeSelectionText(rawSelectionText, 'range', false);
    if (ranges.start > 0 || ranges.end < rawSelectionText.length) {
      const txtNode = selection.anchorNode.firstChild;
      if (txtNode.nextSibling === null) {
        const range = document.createRange();
        range.setStart(txtNode, ranges.start);
        range.setEnd(txtNode, ranges.end);
        selection.removeAllRanges();
        selection.addRange(range);
      }
    }
    // console.log(window.getSelection().toString());
  };

  return (
    <Fragment>
      {dataArray.map((text, itemIx) => {
        let element;

        //* SEARCH & FILTER HIGHLIGHTING
        const searchRegex = new RegExp(searchText, 'ig'); // case insensitive;
        const searchArray = text.split(searchRegex);

        // ? replace parts of a string with styled span elements
        if (hasFilter) {
          const filterRegex = new RegExp(filterText, 'ig'); // case insensitive;
          const filterArray = text.split(filterRegex);
          element = markedElement(filterArray, filterText, 'filterColor');
        } else {
          element = markedElement(searchArray, searchText, 'textFound');
        }

        //* CTRL/META + CLICK TO DEFINITION
        const elements = (
          <span
            role="presentation"
            onClick={wordClickHandler}
            className="highlight-on-hover"
          >
            <ClickToSelect
              onSelect={(
                doTxtSelectFormatting
                  ? normalizeSelection
                  : null
              )}
            >
              {element}
            </ClickToSelect>
          </span>
        );

        // * BREAK EACH ELEMENT TO NEW LINE
        if (newLine) {
          return (
            <div key={`${itemIx}`}>
              {elements}
            </div>
          );
        }

        return (
          <Fragment key={`${itemIx}`}>
            {elements}
          </Fragment>
        );
      })}
    </Fragment>
  );
};

WordElements.propTypes = {
  doTxtSelectFormatting: PropTypes.bool,
  data: PropTypes.any,
  searchText: PropTypes.string,
  hasFilter: PropTypes.bool,
  filterText: PropTypes.string,
  newLine: PropTypes.bool,
  wordClickHandler: PropTypes.func
};

export default WordElements;
