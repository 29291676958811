import React from 'react';
import PropTypes from 'prop-types';
import WordElements from '../FormatHelpers/WordElements';

const rtrnArrayAsElems = (params) => {
  const { searchText } = params;
  const { suggestions } = params;
  const { wordClickHandler } = params;

  return (
    <WordElements
      data={suggestions}
      searchText={searchText}
      wordClickHandler={wordClickHandler}
      newLine
    />
  );
};

const ViewFormat = (props) => {
  console.log('rendering ViewFormat', props);

  const {
    searchText,
    suggestions,
    wordClickHandler
  } = props;

  const htmlComp = rtrnArrayAsElems({
    searchText,
    suggestions,
    wordClickHandler
  });

  return (
    <div className="spellingBox">
      {htmlComp}
    </div>
  );
};

ViewFormat.propTypes = {
  searchText: PropTypes.string,
  suggestions: PropTypes.array,
  wordClickHandler: PropTypes.func
};

export default ViewFormat;
