import clone from 'lodash.clone';
import axios from 'axios';
import { globals } from '../globals';
import Helpers from '../helpers';

export default class Fetch {
  static AllResources(mode, searchText) {
    const sourcesObjArray = Helpers.validSourcesObjArray(mode, searchText, false);
    const sourceKeysArray = Helpers.convertObjectArrayToSimpleArray(sourcesObjArray, 'key');

    // ! START WITH API CALLS
    return Promise.all(sourceKeysArray.map(source => (
      this.FromSource(mode, source, searchText)
        .then(results => results)
        .catch((error) => {
          const { status } = error;
          const { statusText } = error;
          const { data } = error;
          console.log(`${source} (${status}) ${statusText} - ${data}`);
        })
        .finally(() => {
        })
    )));
  }

  static FromSource(mode, source, searchText) {
    return new Promise((resolve, reject) => {
      //* LOCAL DEV
      // if (mode === 'images') {
      //   const results = {};
      //   const d = {
      //     totalHits: 16,
      //     hits: [
      //       {
      //         webformatHeight: 426,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/vgviLsz-7lQcYXhy5oU272B1DyVbaqhkmnhyaa_I25s3mdboHc-0xGV6o9UNrCbtSqjGexqgnl8Jgg',
      //         tags: 'rose, flower, petal',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 426,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/bl67MII011bQOel7u93yufliOlG_7tz09EKZgpmhTejhE9HePAjFsKpbP2SDqez383OwJcmckDpQJA',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 400,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/RMOCuIwcQwTBUfCBB5lqfi0oT_FHTc44tKXGsjKXBPuRsaTyKC7HjNyn_KqD8aKF5PKRjYZigSvAkw',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 426,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/JFuYcOp8re4ZSzOy1tVazYTd__q_11MgdUOI94FnhMeAZ-7kWW4uEI5OAISM8lpCFYLI9bu6PY1DVQ',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 360,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/lEs9VDWNkX98fwdKOPJXsbkJnfMYcByJkOVCOUjKGvxBanYPo2dHDDNNrhqGaAE53jEywmNuI221SA',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 390,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/7Tp5wxUS5E6V2npxrfWBuq2uMDNl_gAULplQSLTUt3ZktMtuWdYoItAqg0g3QRKPiPas4bJdHlU9VQ',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 358,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/IGV4kM3XAoo9M2yV_USNfkkQE2ODr_yYjuPm6oVwKw32ZkrvlO6yUWpxfpSnYK7oLpAY-EU1TUDuVg',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 323,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/W8ReoB-sHUtr1ZXzAaLTtToZahlWOYUZw_mSgHg44yV5GcjaowhcgpeiU1IHlE_MWhzt0J02N8Zc2g',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 419,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/3Fnz9KZAAH8fTk_Mw1rDdp1DZCE26jYqojg1yt26LZPMC3_Keh7INwjKII7DhooxqAbpZFBz5s664Q',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 426,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/5-OLuV_Cyx3tzkidLwFdQivsb9FAr9WVNPAYUfzdUeiucaUUSqJDWYHe3GNKABFGxWgY-GZ-88rpfA',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 428,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/-c8bcooQ_xHDqTY91ddSgXVXG50hz-gVlQn5-tsdt7unYClYNYt5wBmbHb3FUYD87sFAWhj7ZqXCvQ',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 416,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/T7_oAhvWmFfg0EZTzKAmD3BuVMR3JPrdC0OA8tmyLNDMNEPqwoc9niHim16LWxvKiXS7mrW_fpRECQ',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 372,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/8wFKuNHooPMUs8H44-TLlmJvZGg4qaiq--KvVWO_1RXYup5mtpwM2k_Nib_3K9nGxNTqEekJ-EaPEQ',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 497,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/4zuuBGUcfBetAxrvzIvWoEg_ZLvGdhD65Qxfu6syel0sZq0Dcq1n1eUltgF-1JIsV5-tkGtpDtvhlA',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       },
      //       {
      //         webformatHeight: 426,
      //         webformatWidth: 640,
      //         webformatURL: 'https://lh3.googleusercontent.com/DHP0Xl5d1DxlczqRFL0J1QUTCk7hYaD9_1eLLxdTXFXN042E4TEitW2_Cuz3E6nJsxkgFBkGQXw5kg',
      //         tags: 'sunflower, nature, flora',
      //         previewWidth: 150,
      //         previewURL: ''
      //       }
      //     ],
      //     total: 19425
      //   };
      //   results.pixabay = d; // {oxford: data.oxford}
      //   resolve(results);
      // }
      // *

      // * SERVER DEV
      const { baseReq } = globals.axios;
      baseReq.method = globals.netlify.reqProps.method;
      baseReq.baseURL = globals.netlify.reqProps.baseURL;
      baseReq.url = 'getter';
      baseReq.data = { searchText };
      const req = clone(baseReq);
      req.data = { ...baseReq.data, ...{ mode }, ...{ source } };
      // req.params = { source: source };

      axios.request(req)
        .then((res) => { // handles promise resolve
          // console.log(JSON.stringify(res));
          const { resDataKey } = globals.axios; // "data"
          const { sourceKey } = globals[mode].sources[source].resProps; // "oxford"
          const results = {};
          results[sourceKey] = res[resDataKey][sourceKey]; // {oxford: data.oxford}
          resolve(results);
        })
        .catch((error) => { // handles async `getter` function's catch
          // console.log(error);
          if (Helpers.isObjValid(error.response)) {
            reject(error.response);
          } else {
            const customError = {
              status: 408,
              statusText: 'Request Timeout',
              data: 'Axios'
            };
            reject(customError);
          }
        })
        .finally(() => {
          console.log(`${source} fetch promise complete.`);
        });
      // *

    });
  }
}
