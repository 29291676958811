import React from 'react';
import PropTypes from 'prop-types';
import {
  Panel,
  PanelGroup
} from 'rsuite';
import JSPath from 'jspath';
import { globals } from '../../globals';
import ViewFormat from './viewFormat';
import Helpers from '../../helpers';

const WebSearch = (props) => {
  console.log('rendering Web Search', props);

  const { data } = props;
  const { filterText } = props;
  // const { activeModeTab } = props;
  const { activeSourceTab } = props;
  const { wordClickHandler } = props;

  const { results } = data;
  const { searchText } = data;

  if (!Helpers.isObjectSet(results)) {
    return (``);
  }

  //* RETURN EMPTY WHEN NOTHING TO SHOW
  const gotData = Object.keys(results)
    .map(k => Helpers.isArrayFull(results[k]))
    .filter(x => x === true)
    .length > 0;
  if (!gotData) {
    return (``);
  }

  //* PREP & RETURN WEB SEARCH HTML COMP.
  const sourceData = results[activeSourceTab]; // (object array)
  const items = [];
  const sourceObj = globals.web.sources[activeSourceTab];
  const { query } = sourceObj;

  if (Helpers.isArrayFull(sourceData)) {
    let itemKey = '';
    let renderItem;

    // prep the filtering query
    let filterQ = query.filter;
    const hasFilter = Helpers.isStringSet(filterText);
    if (hasFilter) {
      filterQ = filterQ.replace(/{{filterText}}/i, filterText);
    }

    // add results as panels
    for (const [entryIx, entryItem] of sourceData.entries()) {
      const { source, title, snippet, link } = entryItem;

      // render this item? (used by filtering)
      renderItem = true;

      if (hasFilter) {
        const filteredSource = JSPath.apply(filterQ, source);
        const filteredTitle = JSPath.apply(filterQ, title);
        const filteredSnippet = JSPath.apply(filterQ, snippet);
        const matchCount = filteredSource.length + filteredTitle.length + filteredSnippet.length;
        if (matchCount === 0) {
          renderItem = false;
        }
      }

      if (renderItem) {
        const webSearchHtmlComp = (
          <ViewFormat
            searchText={searchText}
            hasFilter={hasFilter}
            filterText={filterText}
            source={source}
            title={title}
            snippet={snippet}
            link={link}
            wordClickHandler={wordClickHandler}
          />
        );

        // add to panel
        itemKey = `${activeSourceTab}-${entryIx}`;
        items.push(
          <Panel key={itemKey}>
            {webSearchHtmlComp}
          </Panel>
        );
      }
    }
  }
  return (
    <PanelGroup>
      {items}
    </PanelGroup>
  );
};

WebSearch.propTypes = {
  data: PropTypes.object,
  // activeModeTab: PropTypes.string,
  activeSourceTab: PropTypes.string,
  filterText: PropTypes.string,
  wordClickHandler: PropTypes.func
};

export default WebSearch;
