import React from 'react';
import PropTypes from 'prop-types';
import { PanelGroup, Panel, Icon } from 'rsuite';
import { Spelling } from './Spelling/Spelling';

const NoResults = (props) => {
  const { searchText } = props;
  const { filterText } = props;
  const { wordClickHandler } = props;

  console.log('rendering [NoResults]', props);

  return (
    <PanelGroup>
      <Panel>
        <Spelling
          searchText={searchText}
          wordClickHandler={wordClickHandler}
        />
      </Panel>
    </PanelGroup>
  );
};

NoResults.propTypes = {
  searchText: PropTypes.string,
  filterText: PropTypes.string,
  wordClickHandler: PropTypes.func
};

export default NoResults;
