import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Helpers from '../../helpers';
import WordElements from '../FormatHelpers/WordElements';

const splitInWordsRtrnElems = (params) => {
  const {
    rawText,
    searchText,
    hasFilter,
    filterText,
    wordClickHandler
  } = params;

  return (
    <WordElements
      doTxtSelectFormatting
      data={rawText}
      searchText={searchText}
      hasFilter={hasFilter}
      filterText={filterText}
      wordClickHandler={wordClickHandler}
    />
  );
};

const ViewFormat = (props) => {
  console.log('rendering ViewFormat', props);

  const {
    searchText,
    hasFilter,
    filterText,
    example,
    wordClickHandler
  } = props;

  const elemParams = {
    hasFilter,
    filterText,
    searchText,
    wordClickHandler
  };

  // example
  let exampleComp = '';
  if (Helpers.isStringSet(example)) {
    elemParams.rawText = example;
    const exampleHtml = splitInWordsRtrnElems(elemParams);
    exampleComp = (
      <div className="new-line">
        <div>
          {exampleHtml}
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      {exampleComp}
    </Fragment>
  );
};

ViewFormat.propTypes = {
  searchText: PropTypes.string,
  hasFilter: PropTypes.bool,
  filterText: PropTypes.string,
  example: PropTypes.string,
  wordClickHandler: PropTypes.func
};

export default ViewFormat;
