import JSPath from 'jspath';
import { globals } from '../../globals';
import Helpers from '../../helpers';

const normalizeGoogleCustomSearch = (props) => {
  const normData = [];

  const { sourceRawData } = props;
  const { sourceQ, titleQ, snippetQ, linkQ } = props;

  for (const sourceRawItem of sourceRawData) {
    // normalize definition raw data
    const source = JSPath.apply(sourceQ, sourceRawItem);
    const dirtyTitle = JSPath.apply(titleQ, sourceRawItem);
    const dirtySnippet = JSPath.apply(snippetQ, sourceRawItem);
    const link = JSPath.apply(linkQ, sourceRawItem);

    if (Helpers.isStringSet(dirtyTitle)
        && Helpers.isStringSet(dirtySnippet)) {
      // clean up - remove square brackets, etc.
      const title = Helpers.cleanText(dirtyTitle);
      const snippet = Helpers.cleanText(dirtySnippet);

      // create normalized item entry
      const normItem = {
        source,
        title,
        snippet,
        link
      };

      normData.push(normItem);
    }
  }
  return normData;
};

const NormalizeWebSearch = (searchText, rawData) => {
  console.log('rendering NormalizeWebSearch', searchText, rawData.length);

  const normData = {};
  const sourcesObjArray = Helpers.validSourcesObjArray('web', searchText, false);
  const sourceKeysArray = Helpers.convertObjectArrayToSimpleArray(sourcesObjArray, 'key');

  for (const source of sourceKeysArray) {
    const resObj = globals.web.sources[source];

    // Given source is unknown to this API, so skip it.
    if (Helpers.isObjectSet(resObj) === false) {
      normData[source] = [];
      break;
    }

    const { query } = resObj;
    let lvl0Q = query.lvl0; // Q for query
    if (Helpers.isObjValid(query.lvl0Filter)) {
      lvl0Q += query.lvl0Filter.replace(/{{searchText}}/g, searchText);
    }
    const sourceRawData = JSPath.apply(lvl0Q, rawData);

    if (Helpers.isArraySet(sourceRawData)) {
      const resQueryProps = {
        sourceRawData, // (object array)
        sourceQ: query.source, // (string)
        titleQ: query.title, // (string)
        snippetQ: query.snippet, // (string)
        linkQ: query.link // (string)
      };

      normData[source] = normalizeGoogleCustomSearch(resQueryProps);
    }
  }
  return normData;
};

export default NormalizeWebSearch;
