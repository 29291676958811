import JSPath from 'jspath';
import { globals } from '../../globals';
import Helpers from '../../helpers';

const normalizeOxford = (props) => {
  const { sourceRawData } = props;
  const { examplesQ } = props;
  const allExamples = JSPath.apply(examplesQ, sourceRawData); // string array
  const examples = [];
  for (const example of allExamples) {
    examples.push(example);
  }
  return examples;
};

const normalizeWiki = (props) => {
  const { sourceRawData } = props;
  const { examplesQ } = props;

  // clean up examples - remove braces, etc.
  const dirtyExamples = JSPath.apply(examplesQ, sourceRawData);
  const examples = [];
  for (const dirtyExample of dirtyExamples) {
    const cleanExample = Helpers.cleanText(dirtyExample);
    examples.push(cleanExample);
  }

  return examples; // string array
};

const normalizeMerriamWebster = (props) => {
  const { searchText } = props;
  const { sourceRawData } = props;
  const { examplesQ } = props;

  // clean up examples - remove braces, etc.
  const dirtyExamples = JSPath.apply(examplesQ, sourceRawData);
  const examples = [];
  for (const dirtyExample of dirtyExamples) {
    if (dirtyExample.toLowerCase().indexOf(`${searchText}`) > -1) {
      const cleanExample = Helpers.cleanText(dirtyExample);
      examples.push(cleanExample);
    }
  }

  return examples; // string array
};

const normalizeUrban = (props) => {
  const { searchText } = props;
  const { sourceRawData } = props;
  const { examplesQ } = props;

  // clean up examples - remove braces, etc.
  const dirtyExamples = JSPath.apply(examplesQ, sourceRawData);
  const examples = [];
  for (const dirtyExample of dirtyExamples) {
    if (dirtyExample.length <= 200
      && dirtyExample.toLowerCase().indexOf(`${searchText}`) > -1) {
      const cleanExample = Helpers.cleanText(dirtyExample);
      examples.push(cleanExample);
    }
  }

  return examples; // string array
};

const normalizeStands4Quotes = (props) => {
  const { sourceRawData } = props;
  const { examplesQ } = props;
  const allExamples = JSPath.apply(examplesQ, sourceRawData); // string array
  const filteredExamples = [];
  for (const quote of allExamples) {
    if (quote.length <= 200) {
      const cleanQuote = Helpers.cleanText(quote);
      filteredExamples.push(cleanQuote);
      if (filteredExamples.length === 80) break;
    }
  }
  return filteredExamples;
};

const normalizeQuoteGarden = (props) => {
  const { sourceRawData } = props;
  const { examplesQ } = props;
  const { authorsQ } = props;
  const examples = [];

  for (const dataObj of sourceRawData) {
    const author = JSPath.apply(authorsQ, dataObj)[0]; // string array
    const authorTxt = author.length > 0 ? ` (by ${author})` : '';
    const dirtyExample = JSPath.apply(examplesQ, dataObj)[0]; // string array
    const cleanExample = Helpers.cleanText(dirtyExample); // clean up examples - remove braces, etc.
    if (cleanExample.length < 400) { // cleanExample.length > 150 &&
      examples.push(`${cleanExample}${authorTxt}`); // in ${title}
    }
  }

  return Helpers.removeDuplicatesFromArray(examples);
};

const normalizeStands4Poetry = (props) => {
  const { sourceRawData } = props;
  const { examplesQ } = props;
  // const { titlesQ } = props;
  const { authorsQ } = props;
  const examples = [];

  for (const dataObj of sourceRawData) {
    const author = JSPath.apply(authorsQ, dataObj)[0]; // string array
    // const title = JSPath.apply(titlesQ, dataObj)[0]; // string array
    const dirtyExample = JSPath.apply(examplesQ, dataObj)[0]; // string array
    const cleanExample = Helpers.cleanText(dirtyExample); // clean up examples - remove braces, etc.
    if (cleanExample.length < 400) { // cleanExample.length > 150 &&
      examples.push(`${cleanExample} (by ${author}).`); // in ${title}
    }
  }

  return examples;
};

const NormalizeExamples = (searchText, rawData) => {
  console.log('rendering NormalizeExamples', searchText, rawData.length);

  const normData = {};
  const sourcesObjArray = Helpers.validSourcesObjArray('examples', searchText, false);
  const sourceKeysArray = Helpers.convertObjectArrayToSimpleArray(sourcesObjArray, 'key');

  for (const source of sourceKeysArray) {
    const resObj = globals.examples.sources[source];

    // Given source is unknown to this API, so skip it.
    if (Helpers.isObjectSet(resObj) === false) {
      normData[source] = [];
      break;
    }

    const { query } = resObj;
    let lvl0Q = query.lvl0; // Q for query
    if (Helpers.isObjValid(query.lvl0Filter)) {
      lvl0Q += query.lvl0Filter.replace(/{{searchText}}/g, searchText);
    }
    const sourceRawData = JSPath.apply(lvl0Q, rawData);

    if (Helpers.isArraySet(sourceRawData)) {
      const resQueryProps = {
        searchText,
        sourceRawData, // (object array)
        examplesQ: query.examples, // Q for query (string array)
        titlesQ: query.titles, // Q for query (string array)
        authorsQ: query.authors, // Q for query (string array)
      };

      switch (source) {
        case 'mw':
          if (normData[source]) {
            normData[source] = normData[source].concat(normalizeMerriamWebster(resQueryProps));
          } else {
            normData[source] = normalizeMerriamWebster(resQueryProps);
          }
          break;
        case 'mw_thesaurus':
          // ! append Wiki data to Merriam Webster's results set for grouping Examples.
          normData[source] = normalizeMerriamWebster(resQueryProps);
          if (normData.mw) {
            normData.mw = normData.mw.concat(normData[source]);
          } else {
            normData.mw = normData[source];
          }
          break;
        case 'urban':
          // ! append Wiki data to Merriam Webster's results set for grouping Examples.
          normData[source] = normalizeUrban(resQueryProps);
          if (normData.mw) {
            normData.mw = normData.mw.concat(normData[source]);
          } else {
            normData.mw = normData[source];
          }
          break;
        case 'wiki':
          // ! append Wiki data to Merriam Webster's results set for grouping Examples.
          normData[source] = normalizeWiki(resQueryProps);
          if (normData.mw) {
            normData.mw = normData.mw.concat(normData[source]);
          } else {
            normData.mw = normData[source];
          }
          break;
        case 'oxford':
          // ! append Oxford data to Merriam Webster's results set for grouping Examples.
          normData[source] = normalizeOxford(resQueryProps);
          if (normData.mw) {
            normData.mw = normData.mw.concat(normData[source]);
          } else {
            normData.mw = normData[source];
          }
          break;
        case 'stands4_quotes':
          normData[source] = normalizeStands4Quotes(resQueryProps);
          break;
        case 'quotegarden':
          normData[source] = normalizeQuoteGarden(resQueryProps);
          break;
        case 'stands4_poetry':
          normData[source] = normalizeStands4Poetry(resQueryProps);
          break;
        default:
          break;
      }
    }
  }
  console.log(normData);
  return normData;
};

export default NormalizeExamples;
