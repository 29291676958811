import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { globals } from '../../globals';
import Helpers from '../../helpers';
import WordElements from '../FormatHelpers/WordElements';

const splitInWordsRtrnElems = (params) => {
  const {
    rawText,
    searchText,
    hasFilter,
    filterText,
    wordClickHandler
  } = params;

  return (
    <WordElements
      doTxtSelectFormatting
      data={rawText}
      searchText={searchText}
      hasFilter={hasFilter}
      filterText={filterText}
      wordClickHandler={wordClickHandler}
    />
  );
};

const ViewFormat = (props) => {
  // console.log('rendering ViewFormat', props);

  const { searchText } = props;
  const { hasFilter } = props;
  const { filterText } = props;

  const { partOfSpeech } = props;
  const { definition } = props;
  const { examples } = props;
  const { synonyms } = props;
  const { antonyms } = props;

  const { wordClickHandler } = props;

  const { viewFormats } = globals;
  const elemParams = {
    hasFilter,
    filterText,
    searchText,
    wordClickHandler
  };

  // part of speech
  let partOfSpeechComp = '';
  if (Helpers.isStringSet(partOfSpeech)) {
    const abbr = viewFormats.partOfSpeech[partOfSpeech.toLowerCase()];
    if (Helpers.isStringSet(abbr)) {
      partOfSpeechComp = (
        <span>{`(${abbr}) `}</span>
      );
    }
  }

  // definition
  let definitionComp = '';
  if (Helpers.isStringSet(definition)) {
    elemParams.rawText = definition;
    const definitionHtml = splitInWordsRtrnElems(elemParams);
    definitionComp = (
      <div className="new-line">
        <div className="col1" title="Definition">def</div>
        <div className="col2">|</div>
        <div className="col3">
          {partOfSpeechComp}
          {definitionHtml}
        </div>
      </div>
    );
  }

  // examples
  let examplesComp = '';
  if (Helpers.isObjValid(examples)) {
    let examplesTxt = '';
    if (Helpers.isStringSet(examples)) {
      examplesTxt = examples;
    } else if (Helpers.isArraySet(examples)) {
      examplesTxt = Helpers.rtrnArrAsCommaDelString(examples);
    }
    if (Helpers.isStringSet(examplesTxt)) {
      elemParams.rawText = examplesTxt;
      const examplesHtml = splitInWordsRtrnElems(elemParams);
      examplesComp = (
        <div className="new-line">
          <div className="col1" title="Example">ex</div>
          <div className="col2">|</div>
          <div className="col3">{examplesHtml}</div>
        </div>
      );
    }
  }

  // synonyms
  let synonymsComp = '';
  if (Helpers.isObjValid(synonyms)) {
    let synonymsTxt = '';
    if (Helpers.isStringSet(synonyms)) {
      synonymsTxt = synonyms;
    } else if (Helpers.isArraySet(synonyms)) {
      synonymsTxt = Helpers.rtrnArrAsCommaDelString(synonyms);
    }
    if (Helpers.isStringSet(synonymsTxt)) {
      elemParams.rawText = synonymsTxt;
      const synonymsHtml = splitInWordsRtrnElems(elemParams);
      synonymsComp = (
        <div className="new-line">
          <div className="col1" title="Synonyms">syn</div>
          <div className="col2">|</div>
          <div className="col3">{synonymsHtml}</div>
        </div>
      );
    }
  }

  // antonyms
  let antonymsComp = '';
  if (Helpers.isObjValid(antonyms)) {
    let antonymsTxt = '';
    if (Helpers.isStringSet(antonyms)) {
      antonymsTxt = antonyms;
    } else if (Helpers.isArraySet(antonyms)) {
      antonymsTxt = Helpers.rtrnArrAsCommaDelString(antonyms);
    }
    if (Helpers.isStringSet(antonymsTxt)) {
      elemParams.rawText = antonymsTxt;
      const antonymsHtml = splitInWordsRtrnElems(elemParams);
      antonymsComp = (
        <div className="new-line">
          <div className="col1" title="Antonyms">ant</div>
          <div className="col2">|</div>
          <div className="col3">{antonymsHtml}</div>
        </div>
      );
    }
  }

  return (
    <Fragment>
      {definitionComp}
      {examplesComp}
      {synonymsComp}
      {antonymsComp}
    </Fragment>
  );
};

ViewFormat.propTypes = {
  searchText: PropTypes.string,
  hasFilter: PropTypes.bool,
  filterText: PropTypes.string,
  partOfSpeech: PropTypes.any,
  definition: PropTypes.any,
  examples: PropTypes.any,
  synonyms: PropTypes.any,
  antonyms: PropTypes.any,
  wordClickHandler: PropTypes.func
};

export default ViewFormat;
