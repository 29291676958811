import React from 'react';
import PropTypes from 'prop-types';
import clone from 'lodash.clone';
import Helpers from '../../helpers';
import WordElements from '../FormatHelpers/WordElements';

const rtrnArrayAsElems = (params) => {
  const { colData } = params;
  const { hasFilter } = params;
  const { searchText } = params;
  const { filterText } = params;
  const { wordClickHandler } = params;

  return (
    <WordElements
      data={colData}
      hasFilter={hasFilter}
      searchText={searchText}
      filterText={filterText}
      wordClickHandler={wordClickHandler}
      newLine
    />
  );
};

const ViewFormat = (props) => {
  console.log('rendering ViewFormat', props);

  const {
    explore,
    hasFilter,
    searchText,
    filterText,
    wordClickHandler
  } = props;

  let exploreArr = clone(explore);
  const items = [];

  const maxNumOfItems = 60;
  const maxNumOfColumns = 3;
  const maxNumOfItemsPerCol = parseInt(maxNumOfItems / maxNumOfColumns);
  const minNumOfItemsPerCol = 10;

  // ? get first maxNumOfItems only
  exploreArr = exploreArr.length > maxNumOfItems
    ? exploreArr.slice(0, maxNumOfItems)
    : exploreArr;

  if (Helpers.isArrayFull(exploreArr)) {
    const totalItemsCount = exploreArr.length;
    let itemsPerCol = parseInt(totalItemsCount / maxNumOfColumns);
    itemsPerCol = itemsPerCol < minNumOfItemsPerCol
      ? minNumOfItemsPerCol
      : maxNumOfItemsPerCol;
    let totalCols = parseInt(totalItemsCount / itemsPerCol);
    totalCols += totalItemsCount % itemsPerCol > 0 ? 1 : 0;
    const colWidthPercent = `${parseInt(100 / totalCols)}%`;

    for (let ix = 1; ix <= totalCols; ix += 1) {
      const startIx = itemsPerCol * (ix - 1);
      const endIx = itemsPerCol * ix;
      // const colHeaderHtml = `<div class="colHeader">Set ${ix}</div><br />`;
      const colData = exploreArr.slice(startIx, endIx); // string array
      const colHtmlComp = rtrnArrayAsElems({
        colData,
        hasFilter,
        searchText,
        filterText,
        wordClickHandler
      });
      items.push(
        <div
          key={`col-${ix}`}
          className="synsCol"
          style={{ flexBasis: `${colWidthPercent}` }}
        >
          {colHtmlComp}
        </div>
      );
    }
  }

  return (
    <div className="synsBox">
      {items}
    </div>
  );
};

ViewFormat.propTypes = {
  explore: PropTypes.array,
  hasFilter: PropTypes.bool,
  searchText: PropTypes.string,
  filterText: PropTypes.string,
  wordClickHandler: PropTypes.func
};

export default ViewFormat;
