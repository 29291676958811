import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { PanelGroup, Panel } from 'rsuite';
import ClickToSelect from '@mapbox/react-click-to-select';
import AnimateText from 'react-pose-text';
import { globals } from '../globals';
import Helpers, { Storage } from '../helpers';

export const SearchHistory = (props) => {
  const { wordClickHandler } = props;
  const items = [];
  const historyData = Storage.getStorageObjValue('historyData'); // array of objects

  console.log('rendering [SearchHistory]', props);

  if (Helpers.isObjUseful(historyData)
    && Helpers.isArrayFull(historyData)) {
    for (const [ix, historyItem] of historyData.entries()) {
      if (ix === 0) {
        items.push(
          <div key={ix} className="highlight-on-hover">
            <ClickToSelect onClick={wordClickHandler}>
              <AnimateText
                initialPose="exit"
                pose="enter"
                charPoses={globals.config.charPoses}
              >
                {historyItem.text}
              </AnimateText>
            </ClickToSelect>
          </div>
        );
      } else {
        items.push(
          <div key={ix}>
            <span
              role="presentation"
              onClick={wordClickHandler}
              className="highlight-on-hover"
            >
              <ClickToSelect>
                {historyItem.text}
              </ClickToSelect>
            </span>
          </div>
        );
      }
    }
  } else {
    items.push(`awaiting search...`);
  }

  return (
    <Fragment>
      <PanelGroup>
        <Panel>
          {items}
        </Panel>
      </PanelGroup>
    </Fragment>
  );
};

SearchHistory.propTypes = {
  wordClickHandler: PropTypes.func
};
