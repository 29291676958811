import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'rsuite';

export class AboutModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };
    this.close = this.close.bind(this);
    this.open = this.open.bind(this);
  }

  close() {
    this.setState({ show: false });
  }

  open() {
    this.setState({ show: true });
  }

  render() {
    const { show } = this.state;
    const { modalRefToBody } = this.props;

    return (
      <div className="modal-container">
        <Modal
          show={show}
          onHide={this.close}
          ref={modalRefToBody}
        >
          <Modal.Header>
            <Modal.Title>About Leximus</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {`made with`}
              <span
                className="emoji emoji-flat emoji-heart"
                style={
                  {
                    marginRight: 7,
                    marginLeft: 7
                  }
                }
              >
                {`❤️`}
              </span>
              {`by `}
              <b>sarpay öner</b>
              <div style={{ marginLeft: 40 }}>in istanbul.</div>
            </div>
            <div className="vertical-spacer-div" />
            <div>
              <span className="emoji" style={{ fontSize: 16 }}>👋🏻</span>
              {` to `}
              <b>oğuzhan kırçalı</b>
              <div style={{ marginLeft: 40 }}>for valuable ideas and motivation.</div>
            </div>
            <div className="vertical-spacer-div" />
            <div>made of;</div>
            <ul>
              <li>ReactJs</li>
              <li>NodeJs (Express)</li>
              <li>Lambda Functions</li>
              <li>Rest API</li>
              <li>JSPath</li>
              <li>RSuite</li>
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.close} appearance="primary">Okay</Button>
            {/* <Button onClick={this.close} appearance="subtle">Cancel</Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

AboutModal.propTypes = {
  modalRefToBody: PropTypes.object
};
