import React from 'react';
import PropTypes from 'prop-types';
import { Panel, Icon } from 'rsuite';

const NoResultsTitlePanel = (props) => {
  console.log('rendering NoResultsTitlePanel', props);

  const { searchText } = props;

  // render items
  return (
    <Panel className="no-results-title-panel">
      <span>
        {/* <Icon icon="right" /> */}
        <span className="emoji emoji-flat"> ❌ </span>
        {`none found for `}
        <span className="resultsTitleText">
          {`${searchText}`}
        </span>
        {`.`}
      </span>
    </Panel>
  );
};

NoResultsTitlePanel.propTypes = {
  searchText: PropTypes.string
};

export default NoResultsTitlePanel;
