import JSPath from 'jspath';
import { globals } from '../../globals';
import Helpers from '../../helpers';

const normalizeMerriamWebster = (props) => {
  const {
    searchText,
    sourceRawData,
    headwordsQ,
    runonsQ,
    variationsQ
  } = props;

  let headwords = JSPath.apply(headwordsQ, sourceRawData); // string array
  headwords = Helpers.cleanArrayOfWords(headwords);

  let runons = JSPath.apply(runonsQ, sourceRawData); // string array
  runons = Helpers.cleanArrayOfWords(runons);

  let variations = JSPath.apply(variationsQ, sourceRawData); // string array
  variations = Helpers.cleanArrayOfWords(variations);

  const unifiedArray = [...headwords, ...runons, ...variations]
    .filter(x => x !== searchText);
  const uniqueArray = Helpers.removeDuplicatesFromArray(unifiedArray);

  return uniqueArray;
};

const normalizeMerriamWebsterSyns = (props) => {
  const { sourceRawData } = props;
  const { synonymsQ } = props;
  return JSPath.apply(synonymsQ, sourceRawData); // string array
};

const normalizeOneLook = (props) => {
  const { sourceRawData } = props;
  const { exploreQ } = props;
  return JSPath.apply(exploreQ, sourceRawData); // string array
};

const normalizeWords = (props) => {
  const { sourceRawData } = props;
  const { exploreQ } = props;
  return JSPath.apply(exploreQ, sourceRawData); // string array
};

const normalizeRhymes = (props) => {
  const { sourceRawData } = props;
  const { exploreQ } = props;
  const commaDelString = JSPath.apply(exploreQ, sourceRawData); // string
  return Helpers.rtrnCommaDelStringAsArr(commaDelString); // string array
};

const NormalizeExplore = (searchText, rawData) => {
  console.log('rendering NormalizeExplore', searchText, rawData.length);

  const normData = {};
  const sourcesObjArray = Helpers.validSourcesObjArray('explore', searchText, false);
  const sourceKeysArray = Helpers.convertObjectArrayToSimpleArray(sourcesObjArray, 'key');

  for (const source of sourceKeysArray) {
    const resObj = globals.explore.sources[source];

    // Given source is unknown to this API, so skip it.
    if (Helpers.isObjectSet(resObj) === false) {
      normData[source] = [];
      break;
    }

    const { query } = resObj;
    let lvl0Q = query.lvl0; // Q for query
    if (Helpers.isObjValid(query.lvl0Filter)) {
      lvl0Q += query.lvl0Filter.replace(/{{searchText}}/g, searchText);
    }

    const sourceRawData = JSPath.apply(lvl0Q, rawData);

    if (Helpers.isArraySet(sourceRawData)) {
      const resQueryProps = {
        searchText,
        sourceRawData, // (object array)
      };

      switch (source) {
        case 'mw':
          resQueryProps.headwordsQ = query.headwords;
          resQueryProps.runonsQ = query.runons;
          resQueryProps.variationsQ = query.variations;
          normData[source] = normalizeMerriamWebster(resQueryProps);
          break;
        case 'mw_thesaurus':
          resQueryProps.synonymsQ = query.synonyms;
          if (normData[source]) {
            normData[source] = Helpers.mergeArraysAndSort(normData[source], normalizeMerriamWebsterSyns(resQueryProps));
          } else {
            normData[source] = normalizeMerriamWebsterSyns(resQueryProps);
          }
          break;
        case 'words':
          // ! append Words data to Merriam Webster's results set for grouping Synonyms.
          resQueryProps.exploreQ = query.explore;
          normData[source] = normalizeWords(resQueryProps);
          if (normData.mw_thesaurus) {
            normData.mw_thesaurus = Helpers.mergeArraysAndSort(normData.mw_thesaurus, normData[source]);
          } else {
            normData.mw_thesaurus = normData[source];
          }
          break;
        case 'onelook':
          resQueryProps.exploreQ = query.explore;
          normData[source] = normalizeOneLook(resQueryProps);
          break;
        case 'stands4_rhymes':
          resQueryProps.exploreQ = query.explore;
          normData[source] = normalizeRhymes(resQueryProps);
          break;
        default:
          break;
      }
    }
  }
  return normData;
};

export default NormalizeExplore;
