import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, Icon } from 'rsuite';
import Helpers from '../helpers';

export class FilterBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterText: ''
    };
    this.setValue = this.setValue.bind(this);
  }

  setValue(val) {
    // * checks Body.filterInputHotKeyHandlers first,
    // * hotkey chars are disallowed.
    const cleanTxt = Helpers.cleanInput(val).toLowerCase();
    this.setState({ filterText: cleanTxt });
  }

  deleteInput() {
    if (this.input === document.activeElement) {
      this.setState({ filterText: '' });
    }
  }

  focusInput() {
    /* native input focus */
    const { input } = this;
    setTimeout(() => { input.focus(); }, 300);
  }

  render() {
    console.log('rendering FilterBox', this.props);

    const { filterText } = this.state;

    const { inputRefToBody } = this.props;
    const { filterClicked } = this.props;
    const { loading } = this.props;
    const { disabled } = this.props;

    const inputOptions = {};
    if (loading || disabled) {
      inputOptions.disabled = 'disabled';
    }

    return (
      <InputGroup inside>
        <Input
          className="filter-box"
          size="lg"
          placeholder="filter results"
          value={filterText}
          ref={inputRefToBody}
          inputRef={(el) => { this.input = el; }}
          onChange={this.setValue}
          {...inputOptions}
        />
        <InputGroup.Addon onClick={filterClicked}>
          <Icon icon="filter" size="lg" />
        </InputGroup.Addon>
      </InputGroup>
    );
  }
}

FilterBox.propTypes = {
  disabled: PropTypes.bool,
  inputRefToBody: PropTypes.object,
  filterClicked: PropTypes.func,
  loading: PropTypes.bool
};
