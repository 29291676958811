import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import uuidv4 from 'uuid/dist/v4';
import Helpers from '../../helpers';
import { globals } from '../../globals';

const ViewFormat = (props) => {
  console.log('rendering Image ViewFormat', props);

  const {
    image,
    imageHeight,
    imageClickHandler,
    isLastImage
  } = props;

  const items = [];

  if (Helpers.isObjectSet(image)) {
    const aspectR = parseFloat(image.bigWidth / image.bigHeight);
    const imageWidth = parseInt(imageHeight * aspectR);
    const imageUrl = image.url.replace('_640', `_${imageHeight}`);
    const imageDivStyle = {
      backgroundImage: `url("${imageUrl}")`,
      width: imageWidth,
      height: imageHeight
    };
    const rightPadding = isLastImage ? 0 : globals.images.config.imageRightPadding;

    const imageElement = (
      <Fragment>
        <div
          className="image-item"
          style={imageDivStyle}
          role="presentation"
          onClick={imageClickHandler}
          data-bigurl={image.bigUrl}
        />
        <div
          style={{ width: rightPadding }}
        />
      </Fragment>
    );

    items.push(
      <Fragment key={uuidv4()}>
        {imageElement}
      </Fragment>
    );
  }

  return (
    <Fragment>
      {items}
    </Fragment>
  );
};

ViewFormat.propTypes = {
  image: PropTypes.object,
  imageHeight: PropTypes.number,
  imageClickHandler: PropTypes.func,
  isLastImage: PropTypes.bool
};

export default ViewFormat;
