import JSPath from 'jspath';
import { globals } from '../../globals';
import Helpers from '../../helpers';
import Fetch from '../../Fetch/Fetch';

const Pronounce = (searchText) => {
  console.log('rendering Pronounce', searchText);

  if (typeof window.Audio === 'function') {
    const mode = 'pronounce';
    const source = 'mw';

    Fetch.FromSource(mode, source, searchText)
      .then((rawData) => {
        const resObj = globals[mode].sources[source];
        const { soundFileUrl } = resObj;
        const { query } = resObj;

        // get the audio file name
        const lvl0Q = query.lvl0;
        const fileNameQ = query.fileName;
        const sourceRawData = JSPath.apply(lvl0Q, rawData);
        const fileName = JSPath.apply(fileNameQ, sourceRawData);

        // determine the folder path (webster madness)
        let folderName = fileName.substr(0, 1);
        if (fileName.substr(0, 2) === 'gg') {
          folderName = `gg`;
        } else if (fileName.substr(0, 3) === 'bix') {
          folderName = `bix`;
        } else if (Helpers.isNumeric(folderName)) {
          folderName = `number`;
        }

        // play the audio file
        const audioElem = new Audio();
        audioElem.src = `${soundFileUrl}/${folderName}/${fileName}.wav`;
        audioElem.play();
      })
      .catch((error) => {
        console.log(error);
      });
  }
};

export default Pronounce;
