require('dotenv').config();

export const globals = {
  netlify: {
    reqProps: {
      baseURL: '/.netlify/functions/',
      method: 'post'
    }
  },
  config: {
    minInputLength: 2,
    verticalScrollHeight: 100,
    storage: {
      storageType: 'localStorage',
      storageTimeToLive: 6000 /* minutes */
    },
    //! builds .darkmode-layer--button class
    darkModeOptions: { // ? https://darkmodejs.learn.uno/
      width: '2rem',
      height: '2rem',
      top: 'unset', // default: '32px'
      right: 'calc(50vw - (2rem / 2))', // default: '32px'
      left: 'unset', // default: 'unset'
      bottom: '2rem', // default: 'unset'
      time: '0.3s', // default: '0.3s'
      mixColor: 'var(--white)', // default: '#fff'
      backgroundColor: 'var(--white)', // background color of page in dark mode
      buttonColorDark: 'var(--white)', // turn to dark mode button
      buttonColorLight: 'var(--eerie-black)', // turn to light mode button
      saveInCookies: true // default: true,
    },
    charPoses: {
      exit: { opacity: 0, y: -20 }, /* transition in down direction  */
      enter: {
        opacity: 1,
        y: 0,
        transition: () => ({
          type: 'spring',
          delay: 0,
          mass: 0.4
        })
      }
    },
    dotPoses: {
      exit: { opacity: 0, y: -20 }, /* transition in down direction  */
      enter: {
        opacity: 1,
        y: 0,
        transition: ({ charIndex }) => ({
          type: 'spring',
          delay: charIndex * 500,
          mass: 0.4,
          stiffness: 200
        })
      }
    }
  },
  viewFormats: {
    partOfSpeech: {
      verb: 'v',
      noun: 'n',
      adjective: 'adj',
      adverb: 'adv',
      pronoun: 'pro',
      conjunction: 'conj',
      preposition: 'pre',
      interjection: 'inter',
      trademark: 'TM',
      contraction: 'contr'
    },
    imageVersions: [
      {
        rangeStart: 0,
        rangeEnd: 827,
        imageHeight: 240
      },
      {
        rangeStart: 828,
        rangeEnd: 9999999999,
        imageHeight: 340
      }
    ]
  },
  modes: {
    list: [
      { key: 'definitions', value: 'Definitions', display: true },
      { key: 'examples', value: 'Usage', display: true },
      { key: 'explore', value: 'Explore', display: true },
      { key: 'translator', value: 'Translations', display: true },
      { key: 'web', value: 'Web', display: true },
      { key: 'images', value: 'Images', display: true },
      { key: 'antonyms', value: 'Antonyms', display: false },
      { key: 'abbreviation', value: 'Abbreviation', display: false },
      { key: 'grammar', value: 'Grammar', display: false },
      { key: 'spelling', value: 'Spelling', display: false }
    ]
  },
  web: {
    sources: {
      list: [
        {
          key: 'google_custom_search_wikipedia',
          value: 'Wikipedia',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'google_custom_search_stem',
          value: 'STEM',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'google_custom_search_humanities',
          value: 'Humanities',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'google_custom_search_geography',
          value: 'Geography',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'google_custom_search_articles',
          value: 'Articles',
          display: true,
          active: true,
          supportsPhrases: true
        }
      ],
      google_custom_search_wikipedia: {
        docs: 'https://developers.google.com/custom-search/v1/using_rest',
        config: 'https://cse.google.com/cse/setup/basic?cx=f0b5cfc52f83b40c7',
        reqProps: {
          baseURL: 'https://www.googleapis.com/',
          url: '/customsearch/v1',
          method: 'get',
          params: {
            key: process.env.GOOGLE_CUSTOM_SEARCH_KEY,
            cx: 'f0b5cfc52f83b40c7'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_custom_search_wikipedia', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'items'
        },
        query: {
          lvl0: '..google_custom_search_wikipedia', // . (object array)
          lvl0Filter: '{.title *= "{{searchText}}" || .snippet *= "{{searchText}}"}',
          source: '.displayLink[0]', // string
          title: '.title[0]', // string
          snippet: '.snippet[0]', // string
          link: '.link[0]', // string
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_custom_search_stem: {
        docs: 'https://developers.google.com/custom-search/v1/using_rest',
        config: 'https://cse.google.com/cse/setup/basic?cx=7a1ce001591f9a838',
        reqProps: {
          baseURL: 'https://www.googleapis.com/',
          url: '/customsearch/v1',
          method: 'get',
          params: {
            key: process.env.GOOGLE_CUSTOM_SEARCH_KEY,
            cx: '7a1ce001591f9a838'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_custom_search_stem', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'items'
        },
        query: {
          lvl0: '..google_custom_search_stem', // . (object array)
          lvl0Filter: '{.title *= "{{searchText}}" || .snippet *= "{{searchText}}"}',
          source: '.displayLink[0]', // string
          title: '.title[0]', // string
          snippet: '.snippet[0]', // string
          link: '.link[0]', // string
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_custom_search_humanities: {
        docs: 'https://developers.google.com/custom-search/v1/using_rest',
        config: 'https://cse.google.com/cse/setup/basic?cx=69083c8e18e0472e0',
        reqProps: {
          baseURL: 'https://www.googleapis.com/',
          url: '/customsearch/v1',
          method: 'get',
          params: {
            key: process.env.GOOGLE_CUSTOM_SEARCH_KEY,
            cx: '69083c8e18e0472e0'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_custom_search_humanities', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'items'
        },
        query: {
          lvl0: '..google_custom_search_humanities', // . (object array)
          lvl0Filter: '{.title *= "{{searchText}}" || .snippet *= "{{searchText}}"}',
          source: '.displayLink[0]', // string
          title: '.title[0]', // string
          snippet: '.snippet[0]', // string
          link: '.link[0]', // string
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_custom_search_geography: {
        docs: 'https://developers.google.com/custom-search/v1/using_rest',
        config: 'https://cse.google.com/cse/setup/basic?cx=3a3fdea32ba175030',
        reqProps: {
          baseURL: 'https://www.googleapis.com/',
          url: '/customsearch/v1',
          method: 'get',
          params: {
            key: process.env.GOOGLE_CUSTOM_SEARCH_KEY,
            cx: '3a3fdea32ba175030'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_custom_search_geography', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'items'
        },
        query: {
          lvl0: '..google_custom_search_geography', // . (object array)
          lvl0Filter: '{.title *= "{{searchText}}" || .snippet *= "{{searchText}}"}',
          source: '.displayLink[0]', // string
          title: '.title[0]', // string
          snippet: '.snippet[0]', // string
          link: '.link[0]', // string
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_custom_search_articles: {
        docs: 'https://developers.google.com/custom-search/v1/using_rest',
        config: 'https://cse.google.com/cse/setup/basic?cx=41202b8df510f593e',
        reqProps: {
          baseURL: 'https://www.googleapis.com/',
          url: '/customsearch/v1',
          method: 'get',
          params: {
            key: process.env.GOOGLE_CUSTOM_SEARCH_KEY,
            cx: '41202b8df510f593e'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_custom_search_articles', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'items'
        },
        query: {
          lvl0: '..google_custom_search_articles', // . (object array)
          lvl0Filter: '{.title *= "{{searchText}}" || .snippet *= "{{searchText}}"}',
          source: '.displayLink[0]', // string
          title: '.title[0]', // string
          snippet: '.snippet[0]', // string
          link: '.link[0]', // string
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      }
    }
  },
  images: {
    config: {
      imageRightPadding: 5,
      freeScrollOffset: 100
    },
    sources: {
      list: [
        {
          key: 'pixabay',
          value: 'Photos',
          display: true,
          active: true,
          supportsPhrases: true
        }
      ],
      pixabay: {
        docs: 'https://pixabay.com/api/docs',
        reqProps: {
          baseURL: 'https://pixabay.com/',
          url: '/api/',
          method: 'get',
          headers: {},
          params: {
            key: process.env.PIXABAY_KEY,
            image_type: 'photo',
            safesearch: true,
            order: 'popular',
            page: 1,
            per_page: 20
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'pixabay', // needs to be same as lvl0
          resultsKey: null
        },
        query: {
          lvl0: '..pixabay', // (object array)
          images: '.hits', // (object array)
          url: '.webformatURL[0]', // string
          width: '.webformatWidth[0]', // string
          height: '.webformatHeight[0]', // string
          bigUrl: '.webformatURL[0]', // string
          bigWidth: '.webformatWidth[0]', // string
          bigHeight: '.webformatHeight[0]', // string
          tags: '.tags[0]' // string
        }
      }
    }
  },
  translator: {
    sources: {
      list: [
        {
          key: 'google_translate_tr',
          value: 'Latin',
          display: true,
          active: true,
          supportsPhrases: true,
          description: 'Turkish'
        },
        {
          key: 'google_translate_es',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true,
          description: 'Spanish'
        },
        {
          key: 'google_translate_pt',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true,
          description: 'Portugese'
        },
        {
          key: 'google_translate_it',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true,
          description: 'Italian'
        },
        {
          key: 'google_translate_fr',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true,
          description: 'French'
        },
        {
          key: 'google_translate_de',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true,
          description: 'German'
        }
      ],
      google_translate_tr: {
        reqProps: {
          baseURL: 'https://translation.googleapis.com/',
          url: '/language/translate/v2',
          method: 'post',
          headers: {},
          params: {
            key: process.env.GOOGLE_TRANSLATE_KEY,
            source: 'en',
            target: 'tr'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_translate_tr', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'data'
        },
        query: {
          lvl0: '..google_translate_tr', // . (object array)
          translatedText: '..translatedText[0]', // (string)
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_translate_es: {
        reqProps: {
          baseURL: 'https://translation.googleapis.com/',
          url: '/language/translate/v2',
          method: 'post',
          headers: {},
          params: {
            key: process.env.GOOGLE_TRANSLATE_KEY,
            source: 'en',
            target: 'es'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_translate_es', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'data'
        },
        query: {
          lvl0: '..google_translate_es', // . (object array)
          translatedText: '..translatedText[0]', // (string)
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_translate_pt: {
        reqProps: {
          baseURL: 'https://translation.googleapis.com/',
          url: '/language/translate/v2',
          method: 'post',
          headers: {},
          params: {
            key: process.env.GOOGLE_TRANSLATE_KEY,
            source: 'en',
            target: 'pt'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_translate_pt', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'data'
        },
        query: {
          lvl0: '..google_translate_pt', // . (object array)
          translatedText: '..translatedText[0]', // (string)
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_translate_it: {
        reqProps: {
          baseURL: 'https://translation.googleapis.com/',
          url: '/language/translate/v2',
          method: 'post',
          headers: {},
          params: {
            key: process.env.GOOGLE_TRANSLATE_KEY,
            source: 'en',
            target: 'it'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_translate_it', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'data'
        },
        query: {
          lvl0: '..google_translate_it', // . (object array)
          translatedText: '..translatedText[0]', // (string)
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_translate_fr: {
        reqProps: {
          baseURL: 'https://translation.googleapis.com/',
          url: '/language/translate/v2',
          method: 'post',
          headers: {},
          params: {
            key: process.env.GOOGLE_TRANSLATE_KEY,
            source: 'en',
            target: 'fr'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_translate_fr', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'data'
        },
        query: {
          lvl0: '..google_translate_fr', // . (object array)
          translatedText: '..translatedText[0]', // (string)
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      google_translate_de: {
        reqProps: {
          baseURL: 'https://translation.googleapis.com/',
          url: '/language/translate/v2',
          method: 'post',
          headers: {},
          params: {
            key: process.env.GOOGLE_TRANSLATE_KEY,
            source: 'en',
            target: 'de'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'google_translate_de', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'data'
        },
        query: {
          lvl0: '..google_translate_de', // . (object array)
          translatedText: '..translatedText[0]', // (string)
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      }
    }
  },
  pronounce: {
    sources: {
      list: [
        {
          key: 'mw',
          value: 'Webster',
          display: false,
          active: true,
          supportsPhrases: true
        }
      ],
      mw: {
        docs: 'https://www.dictionaryapi.com/products/json',
        reqProps: {
          baseURL: 'https://www.dictionaryapi.com/api/v3/references/',
          url: '/collegiate/json/{{searchText}}',
          method: 'get',
          headers: {},
          params: {
            key: process.env.MW_DICT_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'mw',
          resultsKey: null
        },
        query: {
          lvl0: '..mw', // object array
          fileName: '..audio[0]' // string
        },
        soundFileUrl: 'https://media.merriam-webster.com/soundc11'
      }
    }
  },
  examples: {
    sources: {
      list: [
        {
          key: 'mw',
          value: 'Daily',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'mw_thesaurus',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'urban',
          value: '',
          display: false,
          active: false,
          supportsPhrases: true
        },
        {
          key: 'oxford',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'wiki',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'stands4_quotes',
          value: 'Quotes',
          display: false,
          active: false,
          supportsPhrases: false
        },
        {
          key: 'quotegarden',
          value: 'Quotes',
          display: true,
          active: true,
          supportsPhrases: false
        },
        {
          key: 'stands4_poetry',
          value: 'Poetry',
          display: true,
          active: true,
          supportsPhrases: false
        }
      ],
      mw: {
        docs: 'https://www.dictionaryapi.com/products/json',
        reqProps: {
          baseURL: 'https://www.dictionaryapi.com/api/v3/references/',
          url: '/collegiate/json/{{searchText}}',
          method: 'get',
          headers: {},
          params: {
            key: process.env.MW_DICT_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'mw', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: null
        },
        query: {
          lvl0: '..mw', // (object array)
          examples: '..dt..t', // (string array)
          filter: '.{. *= "{{filterText}}"}[0]' // (string)
        }
      },
      mw_thesaurus: {
        docs: 'https://www.dictionaryapi.com/products/json',
        reqProps: {
          baseURL: 'https://www.dictionaryapi.com/api/v3/references/',
          url: '/thesaurus/json/{{searchText}}',
          method: 'get',
          headers: {},
          params: {
            key: process.env.MW_SYNS_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'mw_thesaurus', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: null
          // resultsQ: '.{.meta.id === "{{searchText}}"}'
        },
        query: {
          lvl0: '..mw_thesaurus', // object array
          examples: '..dt{. === "vis"}..t', // string array
          filter: '.{. *= "{{filterText}}"}[0]'
        }
      },
      urban: {
        reqProps: {
          baseURL: 'https://mashape-community-urban-dictionary.p.rapidapi.com/',
          url: '/define',
          method: 'get',
          headers: {
            'X-RapidAPI-Key': process.env.RAPID_API_KEY
          },
          params: {}
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'urban', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'list'
        },
        query: {
          lvl0: '..urban', // . (object array)
          examples: '..example', // .[i].example (string array)
          filter: '.{. *= "{{filterText}}"}[0]'
        }
      },
      oxford: {
        support: 'api@oxforddictionaries.com',
        reqProps: {
          baseURL: 'https://od-api.oxforddictionaries.com/api/v2/entries/en-gb/',
          url: '/{{searchText}}',
          method: 'get',
          headers: {
            app_id: process.env.OXFORD_ID,
            app_key: process.env.OXFORD_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'oxford', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'results'
        },
        query: {
          lvl0: '..oxford', // object array
          examples: '..examples.text', // (string array)
          filter: '.{. *= "{{filterText}}"}[0]'
        }
      },
      wiki: {
        reqProps: {
          baseURL: 'https://en.wiktionary.org/api/rest_v1/',
          url: '/page/definition/{{searchText}}',
          method: 'get',
          headers: {},
          params: {}
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'wiki', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'en'
        },
        query: {
          lvl0: '..wiki', // (object array)
          examples: '..examples', // (string array)
          filter: '.{. *= "{{filterText}}"}[0]'
        }
      },
      stands4_quotes: {
        reqProps: {
          baseURL: 'https://www.stands4.com/services/v2/',
          url: '/quotes.php',
          method: 'get',
          headers: {},
          params: {
            uid: process.env.STANDS4_ID,
            tokenid: process.env.STANDS4_KEY,
            format: 'json',
            searchtype: 'SEARCH'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'stands4_quotes', // needs to be same as lvl0, needs to be same as lvl0
          resultsKey: 'result'
        },
        query: {
          lvl0: '..stands4_quotes', // (object array)
          examples: '.quote', // string array
          filter: '.{. *= "{{filterText}}"}[0]'
        }
      },
      quotegarden: {
        docs: 'https://github.com/pprathameshmore/QuoteGarden',
        reqProps: {
          baseURL: 'https://quote-garden.herokuapp.com/',
          url: '/api/v3/quotes',
          method: 'get',
          headers: {},
          params: {
            limit: 250
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'quotegarden', // needs to be same as lvl0, needs to be same as lvl0
          resultsKey: 'data'
        },
        query: {
          lvl0: '..quotegarden', // (object array)
          authors: '.quoteAuthor', // string array
          examples: '.quoteText', // string array
          filter: '.{. *= "{{filterText}}"}[0]'
        }
      },
      stands4_poetry: {
        reqProps: {
          baseURL: 'https://www.stands4.com/services/v2/',
          url: '/poetry.php',
          method: 'get',
          headers: {},
          params: {
            uid: process.env.STANDS4_ID,
            tokenid: process.env.STANDS4_KEY,
            format: 'json'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'stands4_poetry', // needs to be same as lvl0, needs to be same as lvl0
          resultsKey: 'result'
        },
        query: {
          lvl0: '..stands4_poetry', // (object array)
          authors: '.poet', // string array
          titles: '.title', // string array
          examples: '.poem', // string array
          filter: '.{. *= "{{filterText}}"}[0]'
        }
      }
    }
  },
  explore: {
    sources: {
      list: [
        {
          key: 'mw_thesaurus',
          value: 'Synonyms',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'words',
          value: '',
          display: false,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'onelook',
          value: 'Related',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'mw',
          value: 'Collocation',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'stands4_rhymes',
          value: 'Rhymes',
          display: true,
          active: true,
          supportsPhrases: false
        }
      ],
      mw: {
        docs: 'https://www.dictionaryapi.com/products/json',
        reqProps: {
          baseURL: 'https://www.dictionaryapi.com/api/v3/references/',
          url: '/collegiate/json/{{searchText}}',
          method: 'get',
          headers: {},
          params: {
            key: process.env.MW_DICT_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'mw', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: null
        },
        query: {
          lvl0: '..mw', // (object array)
          headwords: '..hw', // (string array)
          runons: '..drp', // (string array)
          variations: '..va', // (string array)
          filter: '.{. *= "{{filterText}}"}[0]' // (string)
        }
      },
      mw_thesaurus: {
        docs: 'https://www.dictionaryapi.com/products/json',
        reqProps: {
          baseURL: 'https://www.dictionaryapi.com/api/v3/references/',
          url: '/thesaurus/json/{{searchText}}',
          method: 'get',
          headers: {},
          params: {
            key: process.env.MW_SYNS_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'mw', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: null
          // resultsQ: '.{.meta.id === "{{searchText}}"}'
        },
        query: {
          lvl0: '..mw', // object array
          synonyms: '..syn_list..wd', // string array
          filter: '.{. *= "{{filterText}}"}'
        }
      },
      words: {
        reqProps: {
          baseURL: 'https://wordsapiv1.p.rapidapi.com/',
          url: '/words/{{searchText}}/synonyms',
          method: 'get',
          headers: {
            'X-RapidAPI-Key': process.env.RAPID_API_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'words', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: null
        },
        query: {
          lvl0: '..words', // (object array)
          explore: '.synonyms', // (string array)
          filter: '.{. *= "{{filterText}}"}' // (string array)
        }
      },
      onelook: {
        reqProps: {
          baseURL: 'https://api.datamuse.com/',
          url: '/words',
          method: 'get',
          headers: {},
          params: {}
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'onelook', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: null
        },
        query: {
          lvl0: '..onelook', // object array
          explore: '..word', // string array
          filter: '.{. *= "{{filterText}}"}'
        }
      },
      stands4_rhymes: {
        reqProps: {
          baseURL: 'https://www.stands4.com/services/v2/',
          url: '/rhymes.php',
          method: 'get',
          headers: {},
          params: {
            uid: process.env.STANDS4_ID,
            tokenid: process.env.STANDS4_KEY,
            format: 'json'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'stands4_rhymes', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: null
        },
        query: {
          lvl0: '..stands4_rhymes', // object array
          explore: '..rhymes[0]', // string
          filter: '.{. *= "{{filterText}}"}'
        }
      }
    }
  },
  definitions: {
    sources: {
      list: [
        {
          key: 'mw',
          value: 'Webster',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'oxford',
          value: 'Oxford',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'words',
          value: 'Words API',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'stands4_phrases',
          value: 'Stands4',
          display: false,
          active: false,
          supportsPhrases: true
        },
        // { key: 'oxford_syns', value: '', display: false, active: false, supportsPhrases: true },
        // { key: 'oxford_ants', value: '', display: false },
        {
          key: 'wiki',
          value: 'Wiktionary',
          display: true,
          active: true,
          supportsPhrases: true
        },
        {
          key: 'urban',
          value: 'Urban',
          display: false,
          active: false,
          supportsPhrases: true
        },
        {
          key: 'owlbot',
          value: 'OwlBot',
          display: false,
          active: false, // disabled because it gives same or less results as Oxford
          supportsPhrases: true
        }
      ],
      oxford: {
        support: 'api@oxforddictionaries.com',
        reqProps: {
          baseURL: 'https://od-api.oxforddictionaries.com/api/v2/entries/en-gb/',
          url: '/{{searchText}}',
          method: 'get',
          headers: {
            app_id: process.env.OXFORD_ID,
            app_key: process.env.OXFORD_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'oxford', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'results'
        },
        query: {
          lvl0: '..oxford', // object array
          lvl1: '.lexicalEntries',
          pos: '..lexicalCategory[0].id[0]',
          lvl2: '.entries.senses', // object array
          definition: '.definitions[0]', // (string)
          examples: '.examples.text', // (string array)
          synonyms: '.thesaurusLinks.sense_id', // string array
          antonyms: '.thesaurusLinks.sense_id', // string array
          filter: '.{. *= "{{filterText}}"}'
        }
      },
      // oxford_syns: {
      //   reqProps: {
      //     baseURL: 'https://od-api.oxforddictionaries.com/api/v2/thesaurus/en-gb/',
      //     url: '/{{searchText}}',
      //     method: 'get',
      //     headers: {
      //       app_id: process.env.OXFORD_ID,
      //       app_key: process.env.OXFORD_KEY
      //     }
      //   },
      //   resProps: {
      //     responseType: 'json',
      //     sourceKey: 'oxford_syns', // used for consuming provider specific data, needs to be same as lvl0
      //     resultsKey: 'results'
      //   },
      //   query: {
      //     lvl0: '..oxford_syns', // object array
      //     synItem: '..senses{.id === "{{sense_id}}"}', // object array
      //     examples: '.examples.text', // string array
      //     synonyms: '.synonyms.text' // string array
      //   }
      // },
      // oxford_ants: {
      //   reqProps: {
      //     baseURL: 'https://od-api.oxforddictionaries.com/api/v2/thesaurus/en-gb/',
      //     url: '/{{searchText}}',
      //     method: 'get',
      //     headers: {
      //       app_id: process.env.OXFORD_ID,
      //       app_key: process.env.OXFORD_KEY
      //     }
      //   },
      //   resProps: {
      //     responseType: 'json',
      //     sourceKey: 'oxford_ants', // used for consuming provider specific data, needs to be same as lvl0
      //     resultsKey: 'results'
      //   },
      //   query: {
      //     lvl0: '..oxford_ants', // object array
      //     antItem: '..senses{.id === "{{sense_id}}"}', // object array
      //     antonyms: '.antonyms.text' // string array
      //   }
      // },
      mw: {
        docs: 'https://www.dictionaryapi.com/products/json',
        reqProps: {
          baseURL: 'https://www.dictionaryapi.com/api/v3/references/',
          url: '/thesaurus/json/{{searchText}}',
          method: 'get',
          headers: {},
          params: {
            key: process.env.MW_SYNS_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'mw', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: null
          // resultsQ: '.{.meta.id === "{{searchText}}"}'
        },
        query: {
          lvl0: '..mw', // object array
          pos: '.fl[0]', // string
          lvl1: '.def.sseq', // array of arrays
          definition: '..dt{. === "text"}..[1:][0]', // string
          examples: '..dt{. === "vis"}..t', // string array
          synonyms: '..syn_list..wd', // string array
          antonyms: '..ant_list..wd', // string array
          filter: '.{. *= "{{filterText}}"}'
        }
      },
      words: {
        reqProps: {
          baseURL: 'https://wordsapiv1.p.rapidapi.com/',
          url: '/words/{{searchText}}',
          method: 'get',
          headers: {
            'X-RapidAPI-Key': process.env.RAPID_API_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'words', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'results'
        },
        query: {
          lvl0: '..words', // (object array)
          pos: '.partOfSpeech[0]', // (string)
          definition: '.definition[0]', // (string)
          examples: '.examples', // (string array, can be non-existing)
          synonyms: '.synonyms', // (string array)
          antonyms: '.antonyms', // (string array)
          filter: '.{. *= "{{filterText}}"}'
        }
      },
      owlbot: {
        reqProps: {
          baseURL: 'https://owlbot.info/api/v4/dictionary/',
          url: '/{{searchText}}',
          method: 'get',
          headers: {
            'Authorization': process.env.OWLBOT_KEY
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'owlbot', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'definitions'
        },
        query: {
          lvl0: '..owlbot', // (object array)
          pos: '.type[0]', // (string)
          definition: '.definition[0]', // (string)
          examples: '.example[0]', // (string, can be non-existing)
          synonyms: '.notThere', // (string array)
          antonyms: '.notThere', // (string array)
          filter: '.{. *= "{{filterText}}"}'
        }
      },
      stands4_phrases: {
        reqProps: {
          baseURL: 'https://www.stands4.com/services/v2/',
          url: '/phrases.php',
          method: 'get',
          headers: {},
          params: {
            uid: process.env.STANDS4_ID,
            tokenid: process.env.STANDS4_KEY,
            format: 'json'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'stands4_phrases', // needs to be same as lvl0, needs to be same as lvl0
          resultsKey: 'result'
        },
        query: {
          lvl0: '..stands4_phrases', // . (object array)
          lvl0Filter: '{.term *= "{{searchText}}"}',
          pos: '.notThere',
          definition: '.explanation[0]', // .[i].explanation[0] (string)
          examples: '.example[0]', // .[i].example (string array, can return as empty object)
          synonyms: '.notThere',
          antonyms: '.notThere',
          filter: '.{. *= "{{filterText}}"}'
        }
      },
      // stands4: {
      //   reqProps: {
      //     baseURL: 'https://www.stands4.com/services/v2/',
      //     url: '/syno.php',
      //     // url: '/defs.php',
      //     method: 'get',
      //     headers: {},
      //     params: {
      //       uid: process.env.STANDS4_ID,
      //       tokenid: process.env.STANDS4_KEY,
      //       format: 'json'
      //     }
      //   },
      //   resProps: {
      //     responseType: 'json',
      //     sourceKey: 'stands4', // used for consuming provider specific data, needs to be same as lvl0
      //     resultsKey: 'result'
      //   },
      //   query: {
      //     lvl0: '..stands4', // . (object array)
      //     pos: '.partofspeech[0]', // .[i].partofspeech[0] (string)
      //     definition: '.definition[0]', // .[i].definition[0] (string)
      //     examples: '.example[0]', // .[i].example[0] (string, can return as empty object)
      //     synonyms: '.synonyms[0]', // .[i].synonyms[0] (string)
      //     antonyms: '.antonyms[0]', // .[i].antonyms[0] (string)
      //     filter: '.{. *= "{{filterText}}"}'
      //   }
      // },
      wiki: {
        reqProps: {
          baseURL: 'https://en.wiktionary.org/api/rest_v1/',
          url: '/page/definition/{{searchText}}',
          method: 'get',
          headers: {},
          params: {}
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'wiki', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'en'
        },
        query: {
          lvl0: '..wiki', // . (object array)
          pos: '.partOfSpeech[0]', // .[i].partOfSpeech[0] (string)
          lvl1: '.definitions', // .[i].definitions (object array)
          definition: '.definition[0]', // .[i].definitions[i].definition[0] (string)
          examples: '.examples', // .[i].definitions[i].examples (string array)
          synonyms: '.notThere',
          antonyms: '.notThere',
          filter: '.{. *= "{{filterText}}"}'
        }
      },
      urban: {
        reqProps: {
          baseURL: 'https://mashape-community-urban-dictionary.p.rapidapi.com/',
          url: '/define',
          method: 'get',
          headers: {
            'X-RapidAPI-Key': process.env.RAPID_API_KEY
          },
          params: {}
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'urban', // used for consuming provider specific data, needs to be same as lvl0
          resultsKey: 'list'
        },
        query: {
          lvl0: '..urban', // . (object array)
          lvl0Filter: '{.definition *= "{{searchText}}" || .example *= "{{searchText}}" || .word *= "{{searchText}}"}',
          definition: '.definition[0]', // .[i].definition[0] (string)
          examples: '.example', // .[i].example (string array)
          synonyms: '.notThere',
          antonyms: '.notThere',
          filter: '.{. *= "{{filterText}}"}'
        }
      }
    }
  },
  spelling: {
    sources: {
      list: [
        {
          key: 'spellcheck',
          value: 'Bing Spellcheck',
          display: true,
          active: true,
          supportsPhrases: true
        }
      ],
      spellcheck: {
        docs: 'https://docs.microsoft.com/en-us/bing/search-apis/bing-spell-check/quickstarts/rest/nodejs',
        reqProps: {
          baseURL: 'https://api.bing.microsoft.com/',
          url: '/v7.0/SpellCheck/',
          method: 'post',
          headers: {
            'Ocp-Apim-Subscription-Key': process.env.BING_KEY
          },
          params: {
            mkt: 'en-us',
            mode: 'proof' // or use 'spell'
          }
        },
        resProps: {
          responseType: 'json',
          sourceKey: 'spellcheck', // needs to be same as lvl0
          resultsKey: 'flaggedTokens'
        },
        query: {
          lvl0: '..spellcheck', // (object array)
          suggestions: '.suggestions.suggestion' // (string array)
        }
      }
    }
  },
  axios: {
    docs: 'https://www.npmjs.com/package/axios?activeTab=readme',
    resDataKey: 'data',
    baseReq: {
      // `baseURL` will be prepended to `url` unless `url` is absolute.
      // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
      // to methods of that instance.
      baseURL: '',

      // `url` is the server URL that will be used for the request
      url: '',

      method: '',

      headers: {
        'cache-control': 'no-cache'
      },

      // `params` are the URL parameters to be sent with the request
      // Must be a plain object or a URLSearchParams object
      params: {},

      // `data` is the data to be sent as the request body
      // Only applicable for request methods 'PUT', 'POST', and 'PATCH'
      // When no `transformRequest` is set, must be of one of the following types:
      // - string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
      // - Browser only: FormData, File, Blob
      // - Node only: Stream, Buffer
      // data: {}, //! causes bad gateway errors for GET requests. (e.g. Google Custom Search)

      timeout: 5000, // 5 seconds in miliseconds (aws lambda execution limit)

      // `withCredentials` indicates whether or not cross-site Access-Control requests
      // should be made using credentials
      // withCredentials: false, // default

      // `responseType` indicates the type of data that the server will respond with
      // options are 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'
      // responseType: 'json', // default

      // `transformRequest` allows changes to the request data before it is sent to the server
      // This is only applicable for request methods 'PUT', 'POST', and 'PATCH'
      // The last function in the array must return a string or an instance of Buffer, ArrayBuffer,
      // FormData or Stream
      // You may modify the headers object.
      // transformRequest: [function (data, headers) {
      //   return data;
      // }],

      // `transformResponse` allows changes to the response data to be made before
      // it is passed to then/catch
      // transformResponse: [function (data) {
      //   Do whatever you want to transform the data
      //   return data;
      // }],

      // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
      // This will set an `Authorization` header, overwriting any existing
      // `Authorization` custom headers you have set using `headers`.
      // auth: {
      //   username: 'janedoe',
      //   password: 's00pers3cret'
      // },

      // `onUploadProgress` allows handling of progress events for uploads
      // onUploadProgress: function (progressEvent) {

      // },

      // `onDownloadProgress` allows handling of progress events for downloads
      // onDownloadProgress: function (progressEvent) {

      // },

      // `validateStatus` defines whether to resolve or reject the promise for a given
      // HTTP response status code. If `validateStatus` returns `true` (or is set to `null`
      // or `undefined`), the promise will be resolved; otherwise, the promise will be
      // rejected.
      // validateStatus: function (status) {
      //   return status >= 200 && status < 300; // default
      // },

      // `httpAgent` and `httpsAgent` define a custom agent to be used when performing http
      // and https requests, respectively, in node.js. This allows options to be added like
      // `keepAlive` that are not enabled by default.
      // httpAgent: new http.Agent({ keepAlive: true }),
      // httpsAgent: new https.Agent({ keepAlive: true }),

      // 'proxy' defines the hostname and port of the proxy server
      // Use `false` to disable proxies, ignoring environment variables.
      // `auth` indicates that HTTP Basic auth should be used to connect to the proxy, and
      // supplies credentials.
      // This will set an `Proxy-Authorization` header, overwriting any existing
      // `Proxy-Authorization` custom headers you have set using `headers`.

      // proxy: {
      //   host: '127.0.0.1',
      //   port: 9000,
      //   auth: {
      //     username: 'mikeymike',
      //     password: 'rapunz3l'
      //   }
      // }
    }
  }
};
