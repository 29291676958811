import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, InputGroup, Icon } from 'rsuite';
import Helpers from '../helpers';

export class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: ''
    };
    this.setValue = this.setValue.bind(this);
  }

  componentDidMount() {
    this.focusInput();
    //  after all the elements of the page is rendered correctly,
    //  this method is called
    // const layoutDiv = document.querySelector('.layout');
    // const layoutStyle = layoutDiv.currentStyle || window.getComputedStyle(layoutDiv);
    // console.log(layoutStyle.height);
  }

  setValue(val) {
    // * checks Body.searchInputHotKeyHandlers first,
    // * hotkey chars are disallowed.
    const cleanTxt = Helpers.cleanInput(val).toLowerCase();
    this.setState({ searchText: cleanTxt });
  }

  deleteInput() {
    if (this.input === document.activeElement) {
      this.setState({ searchText: '' });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  focusInput() {
    // ? native input focus
    const { input } = this;
    if (Helpers.isDefined(input)) {
      setTimeout(() => {
        // ? first check if focus is in the filterbox and it has a value.
        const { activeElement } = document;
        if (Helpers.isDefined(activeElement.value)
          && Helpers.isDefined(activeElement.className)) {
          if (activeElement.className.indexOf('filter-box') > -1
            && activeElement.hasAttribute('disabled') === false
            && activeElement.value.length > 0) {
            return;
          }
        }
        input.focus();
      }, 300);
    }
  }

  triggerOnChangeEvent() {
    const event = new Event('input', { bubbles: true });
    this.input.dispatchEvent(event);
  }

  render() {
    console.log('rendering SearchBox', this.props);

    const { searchText } = this.state;
    const { inputRefToBody } = this.props;
    const { searchClicked } = this.props;
    const { loading } = this.props;

    const inputOptions = {};
    if (loading) {
      inputOptions.disabled = 'disabled';
    }

    this.focusInput();

    return (
      <InputGroup inside>
        <Input
          className="search-box"
          size="lg"
          placeholder="search word(s)"
          value={searchText}
          ref={inputRefToBody}
          inputRef={(el) => { this.input = el; }}
          onChange={this.setValue}
          {...inputOptions}
        />
        <InputGroup.Addon onClick={searchClicked}>
          <Icon icon="search" size="lg" />
        </InputGroup.Addon>
      </InputGroup>
    );
  }
}

SearchBox.propTypes = {
  inputRefToBody: PropTypes.object,
  searchClicked: PropTypes.func,
  loading: PropTypes.bool
};
