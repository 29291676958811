import React from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'rsuite';
import AnimateText from 'react-pose-text';
import { globals } from '../globals';
import Helpers from '../helpers';
import SvgLib from './Assets/img/SvgLib';
// import NoResults from './NoResults';

const ResultsTitlePanel = (props) => {
  console.log('rendering ResultsTitlePanel', props);

  const { searchText } = props;
  const { filterText } = props;
  const { activeSourceTab } = props;
  const { sourcesList } = props;

  const sourceData = sourcesList.find(obj => obj.key === activeSourceTab);
  const itemsCount = sourceData ? sourceData.count : 0;

  if (itemsCount === 0) {
    return (``);
  }

  let gotFilterText = '';
  if (Helpers.isStringSet(filterText)) {
    gotFilterText = (
      <span>
        {` with filter `}
        <span className="resultsTitleText">
          {`${filterText}`}
        </span>
      </span>
    );
  }

  // add first panel for the searched text header.
  const resultsTitleText = (
    <span>
      {/* <Icon icon="right" /> */}
      {/* <span className="emoji emoji-flat"> ✔️ </span> */}
      {SvgLib.okBadgeIconJsx()}
      <AnimateText
        initialPose="exit"
        pose="enter"
        charPoses={globals.config.charPoses}
      >
        {`${itemsCount}`}
      </AnimateText>
      {` result${itemsCount > 1 ? `s` : ``} for `}
      <span className="resultsTitleText">
        {`${searchText}`}
      </span>
      {gotFilterText}
      {`.`}
    </span>
  );

  // render items
  return (
    <Panel className="results-title-panel">
      {resultsTitleText}
    </Panel>
  );
};

ResultsTitlePanel.propTypes = {
  searchText: PropTypes.string,
  filterText: PropTypes.string,
  activeSourceTab: PropTypes.string,
  sourcesList: PropTypes.array
};

export default ResultsTitlePanel;
