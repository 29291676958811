import React from 'react';
import PropTypes from 'prop-types';
import {
  Panel,
  PanelGroup
} from 'rsuite';
import JSPath from 'jspath';
import { globals } from '../../globals';
import ViewFormat from './viewFormat';
import Helpers from '../../helpers';

const Definitions = (props) => {
  console.log('rendering Definitions', props);

  const { data } = props;
  const { filterText } = props;
  // const { activeModeTab } = props;
  const { activeSourceTab } = props;
  const { wordClickHandler } = props;

  const { results } = data;
  const { searchText } = data;

  if (!Helpers.isObjectSet(results)) {
    return (``);
  }

  //* RETURN EMPTY WHEN NOTHING TO SHOW
  const gotData = Object.keys(results)
    .map(k => Helpers.isArrayFull(results[k]))
    .filter(x => x === true)
    .length > 0;
  if (!gotData) {
    return (``);
  }

  //* PREP & RETURN DEFINITIONS HTML COMP.
  const sourceData = results[activeSourceTab]; // (object array)
  const items = [];
  const sourceObj = globals.definitions.sources[activeSourceTab];
  const { query } = sourceObj;

  if (Helpers.isArrayFull(sourceData)) {
    let itemKey = '';
    let renderItem;

    // prep the filtering query
    let filterQ = query.filter;
    const hasFilter = Helpers.isStringSet(filterText);
    if (hasFilter) {
      filterQ = filterQ.replace(/{{filterText}}/i, filterText);
    }

    // add results as panels
    for (const [entryIx, entryItem] of sourceData.entries()) {
      const { partOfSpeech } = entryItem;
      const { definition } = entryItem;
      const { examples } = entryItem;
      const { synonyms } = entryItem;
      const { antonyms } = entryItem;

      // render this item? (used by filtering)
      renderItem = true;

      if (hasFilter) {
        const filteredDefinition = JSPath.apply(filterQ, definition);
        const filteredExamples = JSPath.apply(filterQ, examples);
        const filteredSynonyms = JSPath.apply(filterQ, synonyms);
        const filteredAntonyms = JSPath.apply(filterQ, antonyms);
        const matchCount = filteredDefinition.length
          + filteredExamples.length
          + filteredSynonyms.length
          + filteredAntonyms.length;
        if (matchCount === 0) {
          renderItem = false;
        }
      }

      if (renderItem) {
        const definitionsHtmlComp = (
          <ViewFormat
            searchText={searchText}
            hasFilter={hasFilter}
            filterText={filterText}
            definition={definition}
            partOfSpeech={partOfSpeech}
            examples={examples}
            synonyms={synonyms}
            antonyms={antonyms}
            wordClickHandler={wordClickHandler}
          />
        );

        // add to panel
        itemKey = `${activeSourceTab}-${entryIx}`;
        items.push(
          <Panel key={itemKey}>
            {definitionsHtmlComp}
          </Panel>
        );
      }
    }
  }
  return (
    <PanelGroup>
      {items}
    </PanelGroup>
  );
};

Definitions.propTypes = {
  data: PropTypes.object,
  // activeModeTab: PropTypes.string,
  activeSourceTab: PropTypes.string,
  filterText: PropTypes.string,
  wordClickHandler: PropTypes.func
};

export default Definitions;
