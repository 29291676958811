import { globals } from './globals';

export default class Helpers {
  static isDefined(obj) {
    return typeof obj !== 'undefined';
  }

  static isObjUseful(obj) {
    if (typeof (obj) === 'undefined' || obj === null) { return false; }
    return true;
  }

  static isObjectSet(obj) {
    return this.isDefined(obj)
      && obj.constructor === Object
      && Object.keys(obj).length > 0;
  }

  static objHasData(obj) {
    const objKeys = Object.keys(obj);
    for (const key of objKeys) {
      if (this.isArrayFull(obj[key])) {
        return true;
      }
    }
    return false;
    // obj: {
    //   key1: [],
    //   key2: []
    // }
    // obj has no data, returns false.

    // obj: {
    //   key1: [1, 2, 3],
    //   key2: []
    // }
    // obj has some data, returns true.
  }

  static isArraySet(obj) {
    return this.isDefined(obj)
      && obj.constructor === Array;
  }

  static isArrayFull(obj) {
    return this.isArraySet(obj)
      && obj.length > 0;
  }

  static isStringSet(obj) {
    return this.isDefined(obj)
      && (obj.constructor === String || typeof obj === 'string' || obj instanceof String)
      && obj.length > 0;
  }

  static isObjValid(obj) {
    return this.isObjectSet(obj)
      || this.isArraySet(obj)
      || this.isStringSet(obj);
  }

  static isNumeric(x) {
    const isIt = /^\d+$/.test(x);
    return isIt;
  }

  static generateKey = pre => `${pre}_${new Date().getTime()}`;

  static rtrnArrAsCommaDelString(arr) {
    let string = '';
    if (this.isArraySet(arr)) {
      string = arr.join(', ');
    }
    return string;
  }

  static rtrnCommaDelStringAsArr(str) {
    if (!this.isStringSet(str)) return [];
    const firstArr = str.split(',');
    const finalArr = [];
    firstArr.forEach((element) => {
      finalArr.push(element.trim());
    });
    finalArr.sort();
    return finalArr;
  }

  static convertObjectArrayToSimpleArray(objArray, propKey) {
    return objArray.map(obj => (obj[propKey]));
    // objArray: [
    //   { key: 'words', value: 'Words API' },
    //   { key: 'oxford', value: 'Oxford' }
    // ]
    // when propKey is passed as `key`; converts it to:
    // ['words', 'oxford']
  }

  static removeDuplicatesFromArray(array) {
    const uniquesArray = array.reduce((newArray, item) => {
      const itemExists = newArray.includes(item);
      const aggrArray = itemExists ? newArray : [...newArray, item];
      return aggrArray;
    }, []); // [] is the initial value of the new array

    return uniquesArray;
  }

  static mergeArraysAndSort(array1, array2) {
    var mergedArray = array1.concat(array2);
    mergedArray = this.removeDuplicatesFromArray(mergedArray);
    return mergedArray.sort();
  }

  static isThisPhrase(text) {
    const spaceRegex = new RegExp(' ', 'g'); // global
    const spacedArray = text.split(spaceRegex);
    return spacedArray.length > 1;
  }

  static validModesObjArray() {
    return globals.modes.list.filter(x => x.display === true);
  }

  static validSourcesObjArray(mode, searchText, checkDisplay) {
    let sourcesObjArray = globals[mode].sources.list.filter(x => x.active);

    if (checkDisplay) {
      sourcesObjArray = sourcesObjArray.filter(x => x.display);
    }

    // ? REGULATE SOURCE SUPPORT FOR PHRASES
    if (this.isThisPhrase(searchText)) {
      sourcesObjArray = sourcesObjArray.filter(x => x.supportsPhrases);
    }

    return sourcesObjArray;
  }

  static cleanText(dirtyTxt) {
    if (this.isStringSet(dirtyTxt)) {
      let cleanTxt = dirtyTxt;

      // ? remove source specified markup
      cleanTxt = cleanTxt.replace(/{/g, '<');
      cleanTxt = cleanTxt.replace(/}/g, '>');
      cleanTxt = cleanTxt.replace(/\[/g, '');
      cleanTxt = cleanTxt.replace(/\]/g, '');

      // ? remove html tags
      cleanTxt = cleanTxt.replace(/(<([^>]+)>)/ig, '');

      // ? decode html codes to actual character. (Stands4 Quotes)
      cleanTxt = this.decodeHTMLEntities(cleanTxt);
      return cleanTxt;
    }
    return '';
  }

  static decodeHTMLEntities(text) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
    // return text.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));
  }

  static cleanInput(dirtyInput) {
    if (this.isStringSet(dirtyInput)) {
      let cleanInput = dirtyInput;
      cleanInput = cleanInput.replace(/['!"#$%&\\'()*+,./:;<=>?@[\\\]^_`{|}~']/g, '');
      return cleanInput;
    }
    return '';
  }

  static cleanArrayOfWords(arrayOfWords) {
    arrayOfWords.forEach((item, index, array) => {
      array[index] = this.cleanInput(item);
    });
    return arrayOfWords;
  }

  static sanitizeSelectionText = (rawText, returnType, allowSpaces) => {
    const text = rawText.trim();
    const range = { start: 0, end: 0 };
    const textLength = text.length;

    // ? find the first index of the character to avoid.
    let startRangeIx = 0;
    for (let si = 0; si < textLength; si += 1) {
      const sChar = text.charAt(si);
      if (this.charIsForbidden(sChar, allowSpaces)) {
        startRangeIx = si + 1;
      } else {
        break;
      }
    }
    // console.log(startRangeIx);

    let endRangeIx = textLength;
    for (let ei = textLength - 1; ei > startRangeIx; ei -= 1) {
      const eChar = text.charAt(ei);
      if (this.charIsForbidden(eChar, allowSpaces)) {
        endRangeIx = ei;
      }
    }
    // console.log(endRangeIx);

    if (returnType === 'range') {
      range.start = startRangeIx;
      range.end = endRangeIx;
      return range;
    }
    if (returnType === 'string') {
      return text.substring(startRangeIx, endRangeIx).toLowerCase();
    }
  };

  static charIsForbidden(char, allowSpace) {
    const restricteds = ['[', ']', '(', ')', '{', '}', '.',
      "'", '!', ',', '"', '?', '@', '/', '\\', '|', '%', '#', '`',
      '$', '^', '&', '*', '_', '+', '=', '>', '<', ';', ':', '~'];

    if (!allowSpace) {
      restricteds.push(' ');
    }

    return this.isStringSet(restricteds.find(x => x === char));
  }

  static emphText(fullText, searchText) {
    const emphRegex = new RegExp(searchText, 'ig'); // case insensitive
    const html = fullText.replace(emphRegex, `<b>${searchText}</b>`);
    return html;
  }

  static colorText(fullText, filterText) {
    // console.log('coloring', filterText);
    const colorRegex = new RegExp(filterText, 'ig'); // case insensitive
    const html = fullText.replace(colorRegex, `<span class='filterColor'>${filterText}</span>`);
    return html;
  }

  static replaceCommasWithBreaks(fullText) {
    // console.log('replace break w/ line break');
    // const commaRegex = new RegExp(','); // case insensitive
    const html = fullText.replace(',', `<br />`);
    return html;
  }

  static removeAllSelection() {
    const selection = window.getSelection();
    if (this.isStringSet(selection.toString())) {
      selection.removeAllRanges();
    }
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/Selection/setBaseAndExtent
  // static selectNextSiblingText() {
  //   const selection = window.getSelection();
  //   const selRange = selection.getRangeAt(0);
  //   let lastNode = selRange.endContainer;
  //   if (typeof (lastNode.className) === 'undefined'
  //     || lastNode.className !== 'highlight-on-hover') {
  //     lastNode = selRange.endContainer.parentNode.parentNode;
  //   }
  //   const nextNode = lastNode.nextSibling;
  //   selection.setBaseAndExtent(lastNode, 0, nextNode, 1);
  // }
}

export class Storage extends Helpers {
  static is(key, value) {
    return this.getStorageItemValue(key) === value;
  }

  static setStorageItemValue(key, value) {
    if (this.isObjUseful(value)) {
      if (this.isDefined(Storage)) {
        const { storageType } = globals.config.storage;
        if (window[storageType]) {
          const storage = window[storageType];
          storage.setItem(key, value);
        }
      }
    }
  }

  static getStorageItemValue(key) {
    let value = null;
    if (this.isDefined(Storage)) {
      const { storageType } = globals.config.storage;
      if (window[storageType]) {
        const storage = window[storageType];
        value = storage.getItem(key);
      }
    }
    return value;
  }

  static setStorageObjValue(key, obj) {
    if (this.isObjUseful(obj)) {
      const { storageType } = globals.config.storage;
      const storage = window[storageType];
      storage.setItem(key, JSON.stringify(obj));
    }
  }

  static getStorageObjValue(key) {
    let value = null;
    if (this.isDefined(Storage)) {
      const { storageType } = globals.config.storage;
      if (window[storageType]) {
        const storage = window[storageType];
        value = storage.getItem(key);
      }
    }
    return JSON.parse(value);
  }

  static removeStorageItem(key) {
    if (this.isDefined(Storage)) {
      const { storageType } = globals.config.storage;
      if (window[storageType]) {
        const storage = window[storageType];
        storage.removeItem(key);
      }
    }
  }

  static removeStorageObj(key) {
    if (this.isDefined(Storage)) {
      const { storageType } = globals.config.storage;
      if (window[storageType]) {
        const storage = window[storageType];
        storage.removeItem(key);
      }
    }
  }

  static clearAllLocalStorage() {
    window.localStorage.clear();
  }

  static clearAllSessionStorage() {
    window.sessionStorage.clear();
  }

  static clearAllStorage() {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  static isStorageObjAlive(storageObjName) {
    const storageObj = this.getStorageObj(storageObjName);
    if (storageObj) {
      const now = new Date();
      if (storageObj.timeStamp) {
        const exp = new Date(storageObj.timeStamp);
        const { storageTimeToLive } = globals.config.storage;
        exp.setMinutes(exp.getMinutes() + storageTimeToLive);
        if (now.getTime() > exp.getTime()) {
          this.removeStorageObj(storageObjName); /* ditch the object if older than expiration. */
        } else {
          return true;
        }
      } else {
        console.log('Storage object does not have a `timeStamp` property.');
      }
    }
    return false;
  }
}
