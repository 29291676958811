/* eslint-disable react/no-unknown-property */
import React, { Component, Fragment } from 'react';
import { HotKeys } from 'react-hotkeys';
import {
  Container,
  Header,
  Content,
  Footer,
  Navbar,
  Nav,
  Icon
} from 'rsuite';
import Darkmode from 'darkmode-js';
import { Body } from './App/Body';
import DimmerSlider from './App/DimmerSlider';
import './App.css';
import 'rsuite/dist/styles/rsuite.min.css';
import { globals } from './globals';
import { Storage } from './helpers';

class App extends Component {
  constructor(props) {
    super(props);
    this.refBody = React.createRef();
    this.applyInteractionMode = this.applyInteractionMode.bind(this);
  }

  // Life Cycle Order:
  // 1. componentWillMount
  // 2. set the initial state in the constructor
  // 3. render()
  // 4. componentDidMount
  // 5. setState()
  // 6. render()

  componentWillMount() {
    // called before any HTML element is rendered
  }

  componentDidMount() {
    //  after all the elements of the page is rendered correctly,
    //  this method is called

    // ? toggle interaction mode (hotkey status) from localstorage
    this.HotKeyMode = Storage.is('hotkeys', 'on');
    this.applyInteractionMode(this.HotKeyMode);

    // ? show darkmode widget
    const { darkModeOptions } = globals.config;
    if (Storage.is('darkmode', 'true')) {
      darkModeOptions.label = `<label class="light">☀︎</label>`;
    } else {
      darkModeOptions.label = `<label class="dark">☾</label>`;
    }

    // const darkmode = new Darkmode(darkModeOptions);
    // darkmode.showWidget();

    // const darkModeButton = darkmode.button;

    // // ? darkmode click listener
    // darkModeButton.addEventListener('click', () => {
    //   if (darkmode.isActivated()) {
    //     darkModeButton.innerText = '☀︎';
    //     darkModeButton.style.setProperty('color', 'var(--white)');
    //   } else {
    //     darkModeButton.innerText = '☾';
    //     darkModeButton.style.setProperty('color', 'var(--body-text-color)');
    //   }
    //   //! recapture focus
    //   this.refBody.current.focusSearchInput();
    // });
  }

  applyInteractionMode = (hotKeysOn) => {
    const toggleCtrlIcon = document.querySelector('.interaction-mode-ctrl i.rs-icon');
    const toggleText = document.querySelector('.interaction-mode-text');
    const html = (`
      <span>
        HotKey mode is ${hotKeysOn ? `ON` : `OFF`}, press&nbsp;
      </span>
      <div class="rs-tag rs-tag-default esc-btn ${hotKeysOn ? `active` : ``}">
        <span class="rs-tag-text">ESC</span>
      </div>
      <span>
        &nbsp;to turn ${hotKeysOn ? `off` : `on`}.
      </span>
    `);
    if (hotKeysOn) {
      toggleCtrlIcon.classList.add('hotkey-icon-on');
      toggleText.classList.add('hotkey-text-on');
    } else {
      toggleCtrlIcon.classList.remove('hotkey-icon-on');
      toggleText.classList.remove('hotkey-text-on');
    }
    toggleText.innerHTML = html;
  }

  toggleInteractionMode() {
    this.HotKeyMode = !this.HotKeyMode;
    const hotKeys = this.HotKeyMode === true ? 'on' : 'off';
    Storage.setStorageItemValue('hotkeys', hotKeys);
    this.applyInteractionMode(this.HotKeyMode);
  }

  render() {
    const showAboutModal = () => {
      this.refBody.current.showAboutModal();
    };

    const openDrawer = () => {
      this.refBody.current.openDrawer();
    };

    const layout = (
      <div className="layout">
        <Container>
          <Header>
            <Navbar appearance="inverse">
              <Navbar.Header>
                <div className="app-logo">LEXIMUS</div>
              </Navbar.Header>
              <Navbar.Body>
                {/* <Nav>
                  <Nav.Item icon={<Icon icon="home" />}>Home</Nav.Item>
                  <Nav.Item>News</Nav.Item>
                  <Nav.Item>Products</Nav.Item>
                  <Dropdown title="About">
                    <Dropdown.Item>Company</Dropdown.Item>
                    <Dropdown.Item>Team</Dropdown.Item>
                    <Dropdown.Item>Contact</Dropdown.Item>
                  </Dropdown>
                </Nav> */}
                <Nav pullRight>
                  <Nav.Item onClick={showAboutModal}>About</Nav.Item>
                  <Nav.Item onClick={openDrawer}>Shortcuts</Nav.Item>
                </Nav>
              </Navbar.Body>
            </Navbar>
          </Header>
          <Content>
            <Body ref={this.refBody} />
          </Content>
          <Footer>
            <div className="footer-wrapper">
              <div className="interaction-mode">
                <div className="interaction-mode-ctrl">
                  <Icon
                    size="lg"
                    icon="keyboard-o"
                  />
                </div>
                <div
                  className="interaction-mode-text"
                  role="presentation"
                />
              </div>
              <DimmerSlider />
              <div className="aikodo-icon"> AiKodo</div>
            </div>
          </Footer>
        </Container>
      </div>
    );

    const keyMap = {
      HOTKEY_MODE_TOGGLE: ['esc'],
      USAGE_TAB: ['ctrl+u', 'meta+u'],
      EXPLORE_TAB: ['ctrl+e', 'meta+e'],
      DEFINITIONS_TAB: ['ctrl+d', 'meta+d'],
      IMAGES_TAB: ['ctrl+i', 'meta+i'],
      NEXT_MODE_TAB: ['ctrl+right', 'shift+right', 'meta+right'],
      PREV_MODE_TAB: ['ctrl+left', 'shift+left', 'meta+left'],
      NEXT_SOURCE_TAB: ['right'],
      PREV_SOURCE_TAB: ['left'],
      SCROLL_DOWN: ['down'],
      SCROLL_UP: ['up'],
      PAGE_DOWN: ['pagedown'],
      PAGE_UP: ['pageup'],
      PRONOUNCE: ['ctrl+enter', 'meta+enter'],
      FOCUS_SEARCHBOX: ['?', 'ctrl+s', 'meta+s'],
      FOCUS_FILTERBOX: ['ctrl+f', 'meta+f'],
      CLEAR_INPUT_TEXT: ['shift+del', 'shift+backspace']
    };

    const handlers = {
      HOTKEY_MODE_TOGGLE: (e) => {
        this.toggleInteractionMode();
      },
      CLEAR_INPUT_TEXT: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.deleteInput();
        }
      },
      PRONOUNCE: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          const selectedText = window.getSelection().toString().trim();
          this.refBody.current.pronounceWord(selectedText);
        }
      },
      FOCUS_SEARCHBOX: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.focusSearchInput(true);
        }
      },
      FOCUS_FILTERBOX: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.focusFilterInput(true);
        }
      },
      USAGE_TAB: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.jumpToMode('examples');
        }
      },
      EXPLORE_TAB: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.jumpToMode('explore');
        }
      },
      DEFINITIONS_TAB: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.jumpToMode('definitions');
        }
      },
      IMAGES_TAB: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.jumpToMode('images');
        }
      },
      NEXT_MODE_TAB: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.jumpToMode('next');
        }
      },
      PREV_MODE_TAB: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.jumpToMode('prev');
        }
      },
      NEXT_SOURCE_TAB: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.jumpToSource('next');
        }
      },
      PREV_SOURCE_TAB: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.jumpToSource('prev');
        }
      },
      SCROLL_DOWN: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.scroll('down');
        }
      },
      SCROLL_UP: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.scroll('up');
        }
      },
      PAGE_DOWN: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.scroll('page-down');
        }
      },
      PAGE_UP: (e) => {
        if (this.HotKeyMode === true) {
          e.preventDefault();
          this.refBody.current.scroll('page-up');
        }
      },
    };

    return (
      <Fragment>
        <HotKeys
          keyMap={keyMap}
          handlers={handlers}
        >
          <div className="App">
            {layout}
          </div>
        </HotKeys>
      </Fragment>
    );
  } // ? end of render()
}

export default App;
