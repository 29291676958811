import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import JSPath from 'jspath';
import { globals } from '../../globals';
import Fetch from '../../Fetch/Fetch';
import LoadingText from '../LoadingText';
import ViewFormat from './ViewFormat';
import Helpers from '../../helpers';

export class Spelling extends Component {
  constructor(props) {
    super(props);
    this.state = {
      failText: '',
      gotSuggestions: false,
      suggestions: []
    };
  }

  componentWillMount() {
    this.setState({ loading: true });
    this.performSearch();
  }

  performSearch() {
    const { searchText } = this.props;
    const mode = 'spelling';
    const source = 'spellcheck';

    Fetch.FromSource(mode, source, searchText)
      .then((rawData) => {
        const resObj = globals[mode].sources[source];
        const { query } = resObj;

        const lvl0Q = query.lvl0;
        const suggestionsQ = query.suggestions;
        const sourceRawData = JSPath.apply(lvl0Q, rawData); // object
        const suggestionsData = JSPath.apply(suggestionsQ, sourceRawData); // string array
        const gotSuggestions = Helpers.isArrayFull(suggestionsData);

        this.setState({
          loading: false,
          failText: gotSuggestions
            ? ''
            : 'spelled correctly or nothing to suggest.',
          gotSuggestions,
          suggestions: gotSuggestions ? suggestionsData : []
        });
      })
      .catch((error) => {
        // ? remove loading text if axios timesout.
        this.setState({
          loading: false,
          failText: error.status === 408
            ? `request timed out - slow or no connection.`
            : `something is broken (${error.status}).`,
          gotSuggestions: false,
          suggestions: []
        });
        console.log(error);
      });
  }

  render() {
    console.log(
      'rendering [Spelling]',
      { props: this.props },
      { state: this.state }
    );

    const { failText } = this.state;
    const { gotSuggestions } = this.state;
    const { suggestions } = this.state;
    const { wordClickHandler } = this.props;
    const { loading } = this.state;
    const { searchText } = this.props;

    return (
      <Fragment>
        {
          loading && (<LoadingText text="Suggestions" />)
        }
        {
          // eslint-disable-next-line no-nested-ternary
          !loading && gotSuggestions
            ? (
              <Fragment>
                <div className="spelling-title">
                  <span className="emoji emoji-flat"> 💡 </span>
                  {`are you looking for one of these?`}
                </div>
                <ViewFormat
                  searchText={searchText}
                  suggestions={suggestions}
                  wordClickHandler={wordClickHandler}
                />
              </Fragment>
            )
            : !loading && !gotSuggestions
              ? (
                <div className="spelling-title">
                  <span className="emoji emoji-flat"> 🏳️󠁳󠁣󠀰󠀱󠁿 </span>
                  {failText}
                </div>
              )
              : null
        }
      </Fragment>
    );
  }
}

Spelling.propTypes = {
  searchText: PropTypes.string,
  wordClickHandler: PropTypes.func
};
