import React from 'react';
import PropTypes from 'prop-types';
import {
  Panel,
  PanelGroup
} from 'rsuite';
import JSPath from 'jspath';
import { globals } from '../../globals';
import ViewFormat from './ViewFormat';
import Helpers from '../../helpers';

const Explore = (props) => {
  console.log('rendering Explore', props);

  const { data } = props;
  const { filterText } = props;
  const { activeModeTab } = props;
  const { activeSourceTab } = props;
  const { wordClickHandler } = props;

  const { results } = data;
  const { searchText } = data;

  if (!Helpers.isObjectSet(results)) {
    return (``);
  }

  //* RETURN EMPTY WHEN NOTHING TO SHOW
  const resultKeys = Object.keys(results);
  const gotData = resultKeys
    .map(k => results[k].length > 0)
    .filter(x => x === true)
    .length > 0;
  if (!gotData) {
    return (``);
  }

  //* PREP & RETURN EXPLORE HTML COMP.
  const sourceData = results[activeSourceTab]; // (object array)
  const items = [];
  const sourceObj = globals.explore.sources[activeSourceTab];
  const { query } = sourceObj;

  if (Helpers.isArrayFull(sourceData)) {
    let renderItem = true;
    let explore = sourceData;

    const hasFilter = Helpers.isStringSet(filterText);
    if (hasFilter) {
      let filterQ = query.filter;
      filterQ = filterQ.replace(/{{filterText}}/i, filterText);
      explore = JSPath.apply(filterQ, explore);
      if (Helpers.isArrayFull(explore) === false) {
        renderItem = false;
      }
    }

    if (renderItem) {
      const htmlComp = (
        <ViewFormat
          explore={explore}
          hasFilter={hasFilter}
          searchText={searchText}
          filterText={filterText}
          wordClickHandler={wordClickHandler}
        />
      );

      // add to panel
      items.push(
        <Panel key={activeSourceTab}>
          {htmlComp}
        </Panel>
      );
    }
  }

  return (
    <PanelGroup>
      {items}
    </PanelGroup>
  );
};

Explore.propTypes = {
  data: PropTypes.object,
  activeModeTab: PropTypes.string,
  activeSourceTab: PropTypes.string,
  filterText: PropTypes.string,
  wordClickHandler: PropTypes.func
};

export default Explore;
